// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction } from '@apollo/client';
import last from 'lodash/last';
import mergeOverlappingSequences from '~/common/utilities/array-utils/mergeOverlappingSequences';
import { GetIdentifierFunction } from './helpers';

const INDEX_NOT_FOUND = -1;

export const mergePaginatedItemsUsing =
  (
    getIdentifierFunction: GetIdentifierFunction,
  ): FieldMergeFunction<any, any> =>
  (existing, incoming, ...props) => {
    const [{ readField }] = props;
    if (!existing) return incoming;

    const { hasMore: existingHasMore } = existing;
    const { hasMore: incomingHasMore } = incoming;
    const existingItems = existing.items;
    const incomingItems = incoming.items;

    const mergedItems = mergeArraysUsingId(getIdentifierFunction)(
      existingItems,
      incomingItems,
      ...props,
    );

    const lastItemId = getIdentifierFunction(last(mergedItems), readField);
    const lastIncomingItemId = getIdentifierFunction(
      last(incomingItems),
      readField,
    );

    const mergedResult = {
      hasMore:
        // if merged items is empty, then assume incoming has more value
        lastItemId === undefined ||
        // incoming is never deduped, so check merged last item's id
        // with the last incoming item's id first
        lastItemId === lastIncomingItemId
          ? incomingHasMore
          : existingHasMore,
      items: mergedItems,
    };

    return mergedResult;
  };

/**
 * A function that finds and merges all unique items based on 'id'
 * while preserving the order of items.
 * NOTE: Assumes existence of 'id' in returned type.
 *
 * @param prefix
 * @param suffix
 * @param ...args
 */
const mergeArraysUsingId =
  (
    getIdentifierFunction: GetIdentifierFunction,
  ): FieldMergeFunction<any[], any[]> =>
  (
    existing = [],
    incoming = [],
    {
      args: { pagination: { before: pagedItemId = undefined } = {} },
      readField,
    },
  ) => {
    if (!existing?.length) return incoming;

    if (pagedItemId === undefined) {
      // If pagedItemId is not defined, then we're fetching
      // the first page.
      const seq = mergeOverlappingSequences({
        preferStart: true,
        startsWith: incoming,
        endsWith: existing,
        equals: (a, b) =>
          getIdentifierFunction(a, readField) ===
          getIdentifierFunction(b, readField),
      });

      if (seq) return seq;

      return incoming;
    }

    // dedup items in existing that are in incoming
    const incomingIdSet = new Set(
      incoming.slice(0).map((task) => getIdentifierFunction(task, readField)),
    );

    existing = existing.filter(
      (task) => !incomingIdSet.has(getIdentifierFunction(task, readField)),
    );

    // Concat [...prefix, ...suffix] after the index of the item used for paging.
    const indexOfPagedItem = existing.findIndex(
      (task) => pagedItemId === getIdentifierFunction(task, readField),
    );

    if (indexOfPagedItem !== INDEX_NOT_FOUND) {
      // if the paged item is in the existing seq,
      // append incoming seq after the paged item in the existing seq
      return [...existing.slice(0, indexOfPagedItem + 1), ...incoming];
    }

    // Otherwise insert incoming at the end of the existing data.
    return [...existing, ...incoming];
  };
