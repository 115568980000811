// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import {
  ApolloClient,
  ApolloProvider,
  from,
  useApolloClient,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { withApollo as withNextApollo } from 'next-with-apollo';
import { FC, PropsWithChildren, useEffect } from 'react';
import { initCache } from '~/common/cache';
import { IS_TEST } from '~/common/config/runtime';
import { useMaze } from '~/hooks/useMaze';
import { useTrackUsage } from '~/hooks/useTrackUsage';
import { logErrorsToDatadogLink } from '../links/logErrorsToDatadogLink';
import { logToDatadogLink } from '../links/logToDatadogLink';
import { splitLinks } from '../links/splitLinks';

/**
 * Middleware for hooks when the apollo client is set
 */
const MiddleWare: FC<PropsWithChildren> = ({ children }) => {
  useTrackUsage();
  useMaze();

  return <>{children}</>;
};

const AddApolloResetToWindow = () => {
  const apollo = useApolloClient();
  useEffect(() => {
    (window as any).apolloReset = apollo.reFetchObservableQueries;
  }, [apollo.reFetchObservableQueries]);
  return <></>;
};

const Apollo = ({ Page, props }) => {
  return (
    <ApolloProvider client={props.apollo}>
      {!IS_TEST && <MiddleWare></MiddleWare>}
      <AddApolloResetToWindow />
      <Page {...props} />
    </ApolloProvider>
  );
};

export const withApollo = withNextApollo(
  ({ ctx, initialState: remoteState }) => {
    const contextHeaders = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        // set cookie from request on the server
        // for SSR authenticated requests
        // NOTE: ctx and initialState will be undefined
        // on the client
        ...(ctx?.req && {
          cookie: ctx.req.headers.cookie,
        }),
      },
    }));

    const cache = initCache({ remoteState });

    const link = IS_TEST
      ? [contextHeaders, splitLinks]
      : [logErrorsToDatadogLink, logToDatadogLink, contextHeaders, splitLinks];

    const client = new ApolloClient({
      link: from(link),
      cache,
    });

    return client;
  },
  {
    render: Apollo,
  },
);
