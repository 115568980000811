// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { useEffect } from 'react';
import {
  useItemLazyQuery,
  useItemUpdatedSubscription,
} from '~/common/gql/item.generated';
import { isBrowser } from '~/common/utilities/browser-utils';

interface Props {
  id: number;
}

export const useItemUpdatedSubscriptionHelper = () => {
  const [itemQuery] = useItemLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { data } = useItemUpdatedSubscription({
    skip: !isBrowser,
  });

  const fetchItem = async ({ id }: Props) => {
    try {
      await itemQuery({
        variables: {
          input: {
            itemId: id,
          },
        },
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (!data) return;
    const { itemUpdatedSubscription: { id } = {} } = data;
    if (id === undefined) return;
    fetchItem({ id });
    // TODO: Fix warning appropriately then remove the line below. We don't want to open reader unless itemId has changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};
