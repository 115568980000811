// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useUserUrlCountLazyQuery } from '~/common/gql/user.generated';

export const useMaze = () => {
  const router = useRouter();
  const [query, { data }] = useUserUrlCountLazyQuery();
  const [url, setUrl] = useState<string>();

  const check = () => {
    if (
      !!router.query['u'] ||
      !url ||
      (data?.userUrlCount || 0) < 3 ||
      !url.includes('/app')
    ) {
      return;
    }

    router.push(
      {
        query: { ...router.query, u: '1' },
      },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    if (!url?.includes('/app')) {
      return;
    }

    const routeChange = (URL) => {
      query().then(() => {
        setUrl(URL);
      });
    };

    router.events.on('routeChangeComplete', routeChange);

    query().then(() => {
      setUrl(window.location.pathname);
      check();
    });
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', routeChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(check, [url, data, router]);
};
