// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

export const copy = {
  changePasswordError: 'There was an error processing your request',
  changePasswordSuccess: 'Your password has been changed.',
  clipboardCopySuccess: 'Copied to clipboard',
  confirmResent: 'Confirmation email resent',
  createCollectionError: 'There was an error creating your collection',
  createCollectionSuccess: 'New collection added!',
  createSectionError: 'There was an error creating the section',
  createSectionSuccess: 'New section added!',
  createItemError: 'There was an error saving your link or URL not valid',
  createItemSuccess: 'Your link has been saved!',
  createNoteError: 'There was an error adding a note',
  createNoteSuccess: 'Note added!',
  deleteAccountError: 'There was an error deleting your account.',
  deleteAccountSuccess: 'Your account has been deleted.',
  deleteCollectionError: 'There was an error deleting your collection',
  deleteCollectionSuccess: 'Collection deleted!',
  deleteItemError: 'There was an error deleting your link',
  deleteItemSuccess: 'Your link has been deleted',
  deleteNoteError: 'There was an error removing the note',
  deleteNoteSuccess: 'Note removed!',
  deleteSectionError: 'There was an error deleting your section',
  deleteSectionSuccess: 'Section deleted!',
  editEmailError: 'There was an error updating your email address',
  editEmailSuccess: 'Check your email to confirm the update!',
  editNoteError: 'There was an error updating the note',
  editNoteSuccess: 'Note updated!',
  getCollectionError:
    'There was an error getting your bookmarks. Try again in a few minutes.',
  getItemError: 'There was an error. Try again in a few minutes.',
  getSearchError: 'There was an error. Try again in a few minutes.',
  loginError: 'Your email address or password was incorrect. Please try again.',
  moveError: 'There was an error moving your bookmark',
  moveSuccess: 'Item moved!',
  passwordResetError: 'There was an error processing your request',
  passwordResetSuccess: 'Your password has been reset. Please log in',
  requestPasswordSuccess: 'If you have an account a link was sent',
  savedToLibrary: 'Saved to library',
  sendFeedbackError: 'There was an error sending your feedback',
  sendFeedbackSuccess: 'Your feedback was sent!',
  signUpError:
    'There was an error signing up. This link may have been used already.',
  signUpSuccess: 'Your account has been created.',
  acceptTerms: 'Accept terms before creating an account.',
  titleEditError: 'There was an error updating title',
  titleEditSuccess: 'Title updated!',
  todoDeleteError: 'There was an error deleting this todo',
  todoMarkedCompleteSucess: 'Marked as complete',
  todoMarkedIncompleteSucess: 'Marked as incomplete',
  todoReadStateError: "There was an error updating this todo's read state",
  todoUpdateError: 'There was an error updating this todo',
  trashItemError: 'There was an error trashing your item',
  trashItemRestoredError: 'There was an error restoring your item',
  trashItemRestoredSuccess: 'Your link has been restored',
  trashItemSuccess: 'Your link has been trashed',
  renameCollectionError: 'There was an error renaming your collection',
  renameCollectionSuccess: 'Collection updated!',
  renameSectionError: 'There was an error renaming your section',
  renameSectionSuccess: 'Section updated!',
  userAccountDeleteError: 'There was an error deleting this user account',
};
