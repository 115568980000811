// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { ApolloLink } from '@apollo/client';
import first from 'lodash/first';
import { v4 } from 'uuid';
import { CLIENT_REQUEST_ID_HEADER_NAME } from '~/common/customHeaders';
import { recursivelyRedactObject } from '~/common/utilities/object-utils';
import { datadog } from '~/lib/datadog/datadog-logger';
import { redactInput } from '../redactInput';

/**
 * Logs all requests to Datadog.
 */
export const logToDatadogLink = new ApolloLink((operation, forward) => {
  const requestDispatchTime = new Date().getTime();
  const clientRequestId = {
    [CLIENT_REQUEST_ID_HEADER_NAME]: v4(),
  };

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...clientRequestId,
    },
  }));

  return forward(operation).map((result) => {
    const context = operation.getContext();
    const requestCompleteTime = new Date().getTime();
    const approximateDurationInMilliseconds =
      requestCompleteTime - requestDispatchTime;
    const redactedOperation = recursivelyRedactObject(operation, redactInput);
    const request_id = context.response?.headers?.get('x-amz-cf-id');
    const request = {
      durationMs: approximateDurationInMilliseconds,
      headers: {
        ...context.headers,
        ...clientRequestId,
      },
      body: {
        ...redactedOperation,
      },
    };

    const response = {
      headers: context?.response?.headers,
      bodyUsed: context?.response?.bodyUsed,
      ok: context?.response?.ok,
      redirected: context?.response?.redirected,
      status: context?.response?.status,
      statusText: context?.response?.statusText,
      type: context?.response?.type,
      url: context?.response?.url,
      body: result,
    };

    const operationName = operation.operationName;
    const operationType = (first(operation.query.definitions) as any).operation;
    const log = datadog.generateLog({
      type: '[GQL Request]',
      message: `${operationType} ${operationName}`,
      context: {
        http: {
          request_id,
        },
        niphtio: {
          request,
          response,
        },
      },
    });

    datadog.logger.then((logger) => {
      if (response.status >= 500) {
        // report 500
        logger.error(...log);
      } else {
        logger.info(...log);
      }
    });
    return result;
  });
});
