// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { FieldFunctionOptions, Reference } from '@apollo/client/cache';
import {
  MutationTodoItemUpdateArgs,
  TodoItemUpdateMutation,
  TodoItemUpdateMutationVariables,
} from '~/common/gql';
import { itemsQueryPaginatedModifier } from '../helpers/itemsQueryPaginated/itemsQueryPaginatedModifier';

type TDocument = TodoItemUpdateMutation;
type TExisting = Reference;
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationTodoItemUpdateArgs;
type TVars = TodoItemUpdateMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [existing, incoming, options] = args;
  const { variables, toReference, cache, readField, mergeObjects } = options;
  const itemRef = readField('item', incoming) as Reference;

  cache.modify({
    fields: {
      itemsQueryPaginated: itemsQueryPaginatedModifier(itemRef),
    },
  });

  return mergeObjects(existing, incoming);
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
