// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

export enum UsageContext {
  NAVIGATION_BAR = 'NAVIGATION_BAR',
  COLLECTION_CARD = 'COLLECTION_CARD',
  TODO = 'TODO',
  SEARCH_CARD = 'SEARCH_CARD',
  FEEDBACK_FORM = 'FEEDBACK_FORM',
  READER = 'READER',
  READER_MOBILE_TOOLBAR = 'READER_MOBILE_TOOLBAR',
}
