// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import { copy } from '~/common/copy/copy';
import { Item, ItemUpdateInput } from '~/common/gql';
import { useItemUpdateMutation } from '~/common/gql/item.generated';
import { ActionType } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';
import { useToastHelper } from './useToastHelper';

export const useItemUpdateMutationBaseHelper = createMutationHelperHook(
  useItemUpdateMutation,
  {
    errorMessage: 'There was an error updating this item',
    log: {
      beforeMutation: (variables) => ({
        actionType:
          variables.input.trashed !== undefined
            ? variables.input.trashed
              ? ActionType.TRASH_BOOKMARK
              : ActionType.UNTRASH_BOOKMARK
            : ActionType.UPDATE_ITEM,
        itemId: variables.input.find.id,
      }),
      afterMutation: (variables) => ({
        actionType:
          variables.input.trashed !== undefined
            ? variables.input.trashed
              ? ActionType.BOOKMARK_TRASHED
              : ActionType.BOOKMARK_UNTRASHED
            : ActionType.ITEM_UPDATED,
        itemId: variables.input.find.id,
      }),
    },
  },
);

export const getTitleValue = (
  value: string,
  item: Pick<Item, 'urlData' | 'title'>,
): ItemUpdateInput['titleValue'] => {
  if (item.urlData && value === item.urlData.title) {
    // if new title is the same as the article's title
    if (item.title === value) {
      // then reset the item title
      return { value: undefined };
    }
    // otherwise noop
    return undefined;
  }
  if (isEmpty(item.title ?? '') && isEmpty(value ?? '')) {
    // if new title and old title are empty then noop
    return undefined;
  }
  return { value };
};

export const getNoteValue = (
  value: string,
  item: Pick<Item, 'note' | 'noteCreatedAt'>,
): ItemUpdateInput['noteValue'] => {
  if (item.noteCreatedAt && item.note === value) {
    // if note hasn't changed, do nothing
    return undefined;
  }
  if (!item.noteCreatedAt && isEmpty((value ?? '').trim())) {
    // if no note has been created and the value is empty or only spaces
    // then don't create a note
    return undefined;
  }

  return { value };
};

export const useItemUpdateMutationHelper = () => {
  const { renderToastWith } = useToastHelper();
  const [updateItem] = useItemUpdateMutationBaseHelper();

  const editTitle = ({
    for: item,
    title,
    onSuccess,
    onError,
  }: {
    for: Pick<Item, 'id' | 'urlData'>;
    title: string;
    onSuccess?: VoidFunction;
    onError?: VoidFunction;
  }) => {
    // if new title is the same as the article's title
    // then reset the item title
    const titleValue = getTitleValue(title, item);

    return updateItem(
      {
        input: {
          find: {
            id: item.id,
          },
          titleValue,
        },
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  const editNote = ({
    for: item,
    note,
    onSuccess,
    onError,
  }: {
    for: Pick<Item, 'id' | 'urlData'>;
    note: string | undefined;
    onSuccess?: VoidFunction;
    onError?: VoidFunction;
  }) => {
    return updateItem(
      {
        input: {
          find: {
            id: item.id,
          },
          noteValue: {
            value: note,
          },
        },
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  const trashItem = (
    item: Pick<Item, 'id'>,
    onSuccess: VoidFunction = undefined,
  ) => {
    return updateItem(
      {
        input: {
          find: {
            id: item.id,
          },
          trashed: true,
        },
      },
      {
        onSuccess: renderToastWith({
          message: copy.trashItemSuccess,
          then: onSuccess,
        }),
        onError: renderToastWith({
          message: copy.trashItemError,
        }),
      },
    );
  };

  const untrashItem = (
    item: Pick<Item, 'id'>,
    onSuccess: VoidFunction = undefined,
  ) => {
    return updateItem(
      {
        input: {
          find: {
            id: item.id,
          },
          trashed: false,
        },
      },
      {
        onSuccess: renderToastWith({
          message: copy.trashItemRestoredSuccess,
          then: onSuccess,
        }),
        onError: renderToastWith({
          message: copy.trashItemRestoredError,
        }),
      },
    );
  };

  return {
    updateItem,
    editTitle,
    editNote,
    trashItem,
    untrashItem,
  };
};
