// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { isBrowser } from './browser-utils';

/**
 * Only works in production because the manifest has to include
 * @returns If the PWA is installed as a related app
 */
export const isPWAInstalled = async () => {
  if (!isBrowser) return false;
  let installed = false;

  // Standalone mode is true if the current web app is open.
  const isStandalone =
    isBrowser &&
    ((window.matchMedia &&
      window.matchMedia('(display-mode: standalone)').matches) ||
      navigator['standalone'] == true);

  if (
    // getInstalledRelatedApps must be invoked in a top-level secure context, that is,
    // not embedded in an <iframe>
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/getInstalledRelatedApps
    window.isSecureContext &&
    'getInstalledRelatedApps' in window.navigator
  ) {
    try {
      // Catches `Failed to execute 'getInstalledRelatedApps' on 'Navigator': Illegal invocation`.
      const relatedApps = await (
        window.navigator as any
      ).getInstalledRelatedApps();
      installed = relatedApps.length > 0;
    } catch (e: any) {}
  }

  return installed || isStandalone;
};
