// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { Item } from '~/common/gql';

export const getItemCollection = (
  itemRef: Reference,
  readField: ReadFieldFunction,
) => {
  const collectionItems = readField(
    'collection',
    itemRef,
  ) as Item['collection'];
  return collectionItems;
};
