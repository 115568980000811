// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldPolicy,
} from '@apollo/client';
import {
  MutationTodoItemDeleteArgs,
  TodoItemDeleteMutationVariables,
} from '~/common/gql';
import { TodoItemDeleteMutation } from '~/common/gql/todoItem.generated';
import { itemsQueryPaginatedModifier } from '../helpers/itemsQueryPaginated/itemsQueryPaginatedModifier';

type TDocument = TodoItemDeleteMutation;
type TExisting = TDocument['todoItemDelete'];
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationTodoItemDeleteArgs;
type TVars = TodoItemDeleteMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 * - Removes item from all item list queries requesting only todos
 * - Evicts normalized todo item storage object from cache
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [, incoming, options] = args;
  const {
    args: {
      find: { id },
    },
    cache,
    readField,
    toReference,
  } = options;

  const todoItem = toReference(`TodoItem:${id}`);
  const itemId = readField('itemId', todoItem);
  const itemRef = toReference(`Item:${itemId}`);

  // remove todo from item
  cache.modify({
    id: cache.identify(itemRef),
    fields: {
      todo: (...args) => {
        const [todo] = args;
        return todo?.filter((it) => readField('id', it) !== id);
      },
    },
  });

  // evict from normalized data
  cache.evict({
    id: cache.identify(todoItem),
  });

  cache.modify({
    fields: {
      itemsQueryPaginated: itemsQueryPaginatedModifier(itemRef),
    },
  });

  return incoming;
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
