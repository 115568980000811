// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { FieldFunctionOptions } from '@apollo/client/cache';
import {
  MutationSectionPreferenceUpdateArgs,
  Section,
  SectionPreferenceUpdateMutation,
  SectionPreferenceUpdateMutationVariables,
} from '~/common/gql';

type TDocument = SectionPreferenceUpdateMutation;
type TExisting = Section;
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationSectionPreferenceUpdateArgs;
type TVars = SectionPreferenceUpdateMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 *
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [existing, incoming, options] = args;
  const { cache, mergeObjects, readField, toReference } = options;

  const sectionRef = toReference({
    __typename: 'Section',
    id: options.args.input.find.id,
  });

  return mergeObjects(existing, incoming);
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
