// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Reference, StoreObject } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

export const removeItem = (
  itemRef: Reference | StoreObject,
  itemsRef: Array<Reference>,
  readField: ReadFieldFunction,
): Array<Reference> => {
  const itemId = readField('id', itemRef);
  return itemsRef.filter((it) => readField('id', it) !== itemId);
};
