// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { HttpLink } from '@apollo/client';
import { GQL_URL } from '~/common/config/runtime';

export const httpLink = new HttpLink({
  credentials: 'include',
  headers: {
    'accept-encoding': 'gzip, defalte, br',
  },
  uri: GQL_URL,
});
