// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { v4 } from 'uuid';

export const getPersistedId = (storage: Storage, key: string) => {
  const windowId = storage.getItem(key);

  if (windowId) return windowId;

  // If windowId has not been created, create an id.
  storage.setItem(key, v4());

  return storage.getItem(key);
};
