// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { ConvenientUsageEventInputs } from '~/common/utilities/usage-event';
import { useUsageEventCreateMutationHelper } from './useUsageEventCreateMutationHelper';

interface THandlers<TData> {
  onSuccess?: (data: TData) => void;
  onError?: (error) => void;
  override?: {
    beforeMutationLog?: (
      input: ConvenientUsageEventInputs,
    ) => ConvenientUsageEventInputs;
    afterMutationLog?: (
      input: ConvenientUsageEventInputs,
    ) => ConvenientUsageEventInputs;
  };
}

interface OptionProps<TVariables, TData> {
  errorMessage: string;
  transformVariables?: (variables: TVariables) => TVariables;
  log?: {
    beforeMutation: (variables: TVariables) => ConvenientUsageEventInputs;
    afterMutation: (
      variables: TVariables,
      data: TData,
    ) => ConvenientUsageEventInputs;
  };
}

export const createMutationHelperHook = <
  TData,
  TVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  useMutation: (
    baseOptions?: MutationHookOptions<TData, TVariables, TContext, TCache>,
  ) => MutationTuple<TData, TVariables, TContext, TCache>,
  options: OptionProps<TVariables, TData>,
): ((
  baseOptions?: MutationHookOptions<TData, TVariables, TContext, TCache>,
) => [
  (variables: TVariables, handlers?: THandlers<TData>) => Promise<void>,
  ReturnType<typeof useMutation>[1],
]) => {
  return (baseOptions) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mutation, mutationResult] = useMutation(baseOptions);
    const { logUsageEvent } = useUsageEventCreateMutationHelper();

    const mutate = async (
      variables: TVariables,
      handlers?: THandlers<TData>,
    ) => {
      if (options?.log?.beforeMutation) {
        const event = options.log.beforeMutation(variables);
        const txEvent = handlers?.override?.beforeMutationLog?.(event);
        logUsageEvent(txEvent ?? event);
      }

      try {
        const txVariables = options?.transformVariables
          ? options?.transformVariables(variables)
          : variables;
        const result = await mutation({
          variables: txVariables,
        });

        if (options?.log?.afterMutation) {
          const event = options.log.afterMutation(variables, result.data);
          const txEvent = handlers?.override?.afterMutationLog?.(event);
          await logUsageEvent(txEvent ?? event);
        }

        handlers?.onSuccess?.(result.data);
      } catch (e) {
        handlers?.onError?.(e);
      }
    };

    return [mutate, mutationResult];
  };
};
