// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { withItemsQueryVariablesFn } from '~/common/cache/helpers/itemsQueryPaginated/types/withItemsQueryVariablesFn';

/**
 *
 * @param it
 * @returns true if query returns trashed items
 */
export const getIsAnyTrashItemsQueryVariables: withItemsQueryVariablesFn = (
  it,
) => {
  return (
    it && //
    it.variables && //
    it.variables.input?.trashed === true // is trashed only
  );
};
