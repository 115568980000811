// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { TParsedQueryProperties } from './TParsedQueryProperties';

/**
 * Regex that matches name and string of apollo storage field names.
 *
 * Example
 *
 * > input: itemsQueryPaginated:{"input":{"orphansOnly":false,"todosOnly":false,"trashed":false},"pagination":{"order":"DESC"}}
 * > name: itemsQueryPaginated
 * > variableAsString: {"input":{"orphansOnly":false,"todosOnly":false,"trashed":false},"pagination":{"order":"DESC"}}
 */
const RegexMatchNameAndString = /^([a-zA-Z]+)[:(]?([^\(\)]*)\)?/;

/**
 * Apollo's storeFieldName is the key it uses to store
 * queries in cache. It is a string that contains the query
 * name and variables.
 * This function takes Apollo's storeFieldName and returns the
 * query's name and variables.
 *
 * Example
 * input storeFieldName: itemsQueryPaginated:{"input":{"orphansOnly":false,"todosOnly":false,"trashed":false},"pagination":{"order":"DESC"}}
 *
 * output: {
 *   name: 'itemsQueryPaginated',
 *   variables: {"input":{"orphansOnly":false,"todosOnly":false,"trashed":false},"pagination":{"order":"DESC"}},
 *   result: {...}
 * }
 * @param storeFieldName storeFieldName provided by Apollo for cached queries
 * @returns An object containing
 *          * name: name of query
 *          * variables: variables passed to query as an object, or undefined
 */
export const parseStoreFieldName = <T>(
  storeFieldName: string,
): TParsedQueryProperties<T> => {
  const patternMatch = storeFieldName.match(RegexMatchNameAndString);
  const name = patternMatch?.[1];
  const varAsString = patternMatch?.[2];
  const variables = varAsString ? JSON.parse(varAsString) : undefined;
  return {
    name,
    variables,
  };
};
