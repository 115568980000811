// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldPolicy,
} from '@apollo/client';
import {
  KeyArgsFunction,
  KeySpecifier,
} from '@apollo/client/cache/inmemory/policies';
import { ItemsQuery, QueryItemsQueryPaginatedArgs } from '~/common/gql';
import { ItemsQueryStoreFieldNameVariables } from '../helpers/itemsQueryPaginated/types/ItemsQueryStoreFieldNameVariables';
import { getIdFromItem } from './helpers/helpers';
import { mergePaginatedItemsUsing } from './helpers/mergePaginatedItemsUsing';

type TDocument = ItemsQuery;
type TExisting = TDocument['itemsQueryPaginated'];
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = QueryItemsQueryPaginatedArgs;
type TVars = QueryItemsQueryPaginatedArgs;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TKeyArgs = KeySpecifier | KeyArgsFunction | false;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

const keyArgs: TKeyArgs = (args) => {
  const {
    input: { collectionId, orphansOnly, todosOnly, todoOptions, trashed },
    pagination: { order },
  } = args as TArg;

  const key: TArg = {
    input: {
      collectionId,
      orphansOnly,
      todosOnly,
      todoOptions,
      trashed,
    },
    pagination: {
      order,
    },
  } satisfies ItemsQueryStoreFieldNameVariables;

  return JSON.stringify(key);
};

const merge: TMergeFunction = mergePaginatedItemsUsing(getIdFromItem);

export default {
  keyArgs,
  merge,
} satisfies TPolicyInternal as TPolicy;
