// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { DebounceSettings } from 'lodash';
import debounce from 'lodash/debounce';
import { datadog } from '~/lib/datadog/datadog-logger';

export interface ScrollEventProps {
  direction: 'up' | 'down';
  event: any;
}

export const onScrollDebounced = (
  onScroll: (sep: ScrollEventProps) => void,
  wait = 250,
  options?: DebounceSettings,
) => {
  let yPositionOfTarget = 0;
  return debounce(
    (event) => {
      const y = event.target.documentElement?.scrollTop;

      if (y === undefined) {
        const log = datadog.generateLog({
          type: '[Monitoring]',
          message: 'Unable to get y position of target from scroll event.',
          context: { debug: { event } },
        });
        datadog.logger.then((logger) => logger.warn(...log));
        return;
      }

      onScroll({
        direction: yPositionOfTarget < y ? 'down' : 'up',
        event,
      });

      yPositionOfTarget = y;
    },
    wait,
    options,
  );
};
