// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

export const getIsItemOrphan = (
  itemRef: Reference,
  readField: ReadFieldFunction,
) => {
  const collectionItems = readField('collection', itemRef) as Reference[];
  return !(collectionItems?.length > 0);
};
