// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

export const redactInput = [
  // 'email',
  // 'itemUrl',
  // 'name',
  // 'newEmail',
  'password',
  // 'query',
  // 'title',
  //'token', should this be included?,
  // 'url',
  // 'urls',
];
