// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import {
  Order,
  PaginationOptionsInput,
  TodoOnlyOptionsInput,
} from '~/common/gql';
import { useItemsLazyQuery, useItemsQuery } from '~/common/gql/item.generated';
import { useItemsPagingHelper } from '~/hooks/useItemsPagingHelper';

type QueryProps = Parameters<typeof useItemsQuery>[0];

interface Props extends QueryProps {
  collectionId: number;
  orphansOnly?: boolean;
  trashed?: boolean;
  todosOnly?: boolean;
  todoOptions?: TodoOnlyOptionsInput;
  /* only applies to the first page query */
  pagination?: PaginationOptionsInput;
}

export const useItemsQueryPagingHelper = ({
  collectionId,
  orphansOnly = false,
  trashed = false,
  todosOnly = false,
  todoOptions = undefined,
  pagination,
  ...queryOptions
}: Props) => {
  const options: QueryProps = {
    variables: {
      input: {
        collectionId,
        orphansOnly,
        todosOnly,
        todoOptions,
        trashed,
      },
      pagination: {
        limit: 20,
        order: Order.Desc,
      },
    },
    ssr: false,
    ...queryOptions,
  };

  const result = useItemsQuery({
    notifyOnNetworkStatusChange: true,
    ...options,
    variables: {
      ...options.variables,
      pagination: { ...options.variables.pagination, ...pagination },
    },
  });

  const {
    data: { itemsQueryPaginated: { hasMore, items } = {} } = {},
    fetchMore,
  } = result;

  const pagingHelperResults = useItemsPagingHelper({
    itemIds: items?.map((it) => it.id)?.filter((it) => !isNil(it)),
    options,
    fetchMore,
  });

  return { items, ...result, ...pagingHelperResults, hasMore };
};
