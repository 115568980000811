import * as Types from '../gql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPreferenceFragmentFragment = { __typename?: 'UserPreference', collapseMyCollections?: boolean | null, collapseFollowingCollections?: boolean | null, collectionLayout?: Types.LayoutType | null, hideAddBookmarkHint?: boolean | null, hideCardManageTagsHint?: boolean | null, hideCardTodoMarkDoneHint?: boolean | null, hideCreateTagHint?: boolean | null, hideWelcomeMessage?: boolean | null };

export type LoginQueryVariables = Types.Exact<{
  input: Types.LoginInput;
}>;


export type LoginQuery = { __typename?: 'Query', login: { __typename?: 'User', id: any, email: string, name: string, permissions?: Array<Types.Permission> | null, defaultCollectionsEnabled: boolean } };

export type UserUrlCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserUrlCountQuery = { __typename?: 'Query', userUrlCount: number };

export type MeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', self: { __typename?: 'User', id: any, email: string, name: string, permissions?: Array<Types.Permission> | null, defaultCollectionsEnabled: boolean }, userSettings: Array<{ __typename?: 'UserSetting', collectionId?: any | null, settingType?: Types.CollectionType | null }> };

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type RequestAccountMutationVariables = Types.Exact<{
  input: Types.RequestAccountInput;
}>;


export type RequestAccountMutation = { __typename?: 'Mutation', requestAccount: boolean };

export type ValidateAccountSetupTokenQueryVariables = Types.Exact<{
  input: Types.TokenInput;
}>;


export type ValidateAccountSetupTokenQuery = { __typename?: 'Query', validateAccountSetupToken: boolean };

export type SetupAccountMutationVariables = Types.Exact<{
  input: Types.SetupAccountInput;
}>;


export type SetupAccountMutation = { __typename?: 'Mutation', setupAccount: { __typename?: 'User', id: any } };

export type RequestPasswordResetMutationVariables = Types.Exact<{
  email: Types.RequestPasswordResetInput;
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: boolean };

export type ResetPasswordMutationVariables = Types.Exact<{
  input: Types.ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'User', id: any } };

export type ChangeEmailMutationVariables = Types.Exact<{
  newEmail: Types.Scalars['String']['input'];
}>;


export type ChangeEmailMutation = { __typename?: 'Mutation', requestEmailChange: boolean };

export type ConfirmEmailChangeMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type ConfirmEmailChangeMutation = { __typename?: 'Mutation', confirmEmailChange?: { __typename?: 'User', email: string } | null };

export type ChangePasswordMutationVariables = Types.Exact<{
  password: Types.Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', authenticatedPasswordChange: { __typename?: 'User', id: any } };

export type UserDeleteMutationVariables = Types.Exact<{
  input: Types.UserDeleteInput;
}>;


export type UserDeleteMutation = { __typename?: 'Mutation', userDelete: boolean };

export type AdminUserDeleteMutationVariables = Types.Exact<{
  input: Types.UserDeleteInput;
}>;


export type AdminUserDeleteMutation = { __typename?: 'Mutation', adminUserDelete: boolean };

export type UserFeedbackMutationVariables = Types.Exact<{
  input: Types.Scalars['String']['input'];
}>;


export type UserFeedbackMutation = { __typename?: 'Mutation', userFeedback: boolean };

export type AdminListUsersQueryVariables = Types.Exact<{
  input: Types.AdminFindUsersInput;
}>;


export type AdminListUsersQuery = { __typename?: 'Query', adminListUsers: Array<{ __typename?: 'User', createdAt?: any | null, email: string, id: any, name: string, permissions?: Array<Types.Permission> | null, role?: Types.Role | null }> };

export type AddSettingMutationVariables = Types.Exact<{
  input: Types.SettingInput;
}>;


export type AddSettingMutation = { __typename?: 'Mutation', addSetting?: { __typename?: 'UserSetting', settingType?: Types.CollectionType | null } | null };

export type SettingRemoveMutationVariables = Types.Exact<{
  input: Types.SettingInput;
}>;


export type SettingRemoveMutation = { __typename?: 'Mutation', removeSetting: number };

export type UpdateUserMutationVariables = Types.Exact<{
  input: Types.UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', defaultCollectionsEnabled: boolean, id: any } | null };

export type UserPreferenceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserPreferenceQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', collapseMyCollections?: boolean | null, collapseFollowingCollections?: boolean | null, collectionLayout?: Types.LayoutType | null, hideAddBookmarkHint?: boolean | null, hideCardManageTagsHint?: boolean | null, hideCardTodoMarkDoneHint?: boolean | null, hideCreateTagHint?: boolean | null, hideWelcomeMessage?: boolean | null } | null };

export type UpdatePreferenceMutationVariables = Types.Exact<{
  input: Types.UserPreferenceInput;
}>;


export type UpdatePreferenceMutation = { __typename?: 'Mutation', updatePreference: { __typename?: 'UserPreference', collapseMyCollections?: boolean | null, collapseFollowingCollections?: boolean | null, collectionLayout?: Types.LayoutType | null, hideAddBookmarkHint?: boolean | null, hideCardManageTagsHint?: boolean | null, hideCardTodoMarkDoneHint?: boolean | null, hideCreateTagHint?: boolean | null, hideWelcomeMessage?: boolean | null } };

export type NotesQuotaQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NotesQuotaQuery = { __typename?: 'Query', notesQuota: { __typename?: 'NotesQuotaResult', maximum?: number | null, remaining?: number | null } };

export type SharedCollectionsQuotaQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SharedCollectionsQuotaQuery = { __typename?: 'Query', sharedCollectionsQuota: { __typename?: 'QuotaBalanceResult', maximum?: number | null, remaining?: number | null } };

export const UserPreferenceFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"userPreferenceFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserPreference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collapseMyCollections"}},{"kind":"Field","name":{"kind":"Name","value":"collapseFollowingCollections"}},{"kind":"Field","name":{"kind":"Name","value":"collectionLayout"}},{"kind":"Field","name":{"kind":"Name","value":"hideAddBookmarkHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCardManageTagsHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCardTodoMarkDoneHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCreateTagHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideWelcomeMessage"}}]}}]} as unknown as DocumentNode;
export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"login"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"permissions"}},{"kind":"Field","name":{"kind":"Name","value":"defaultCollectionsEnabled"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const UserUrlCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userUrlCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userUrlCount"}}]}}]} as unknown as DocumentNode;

/**
 * __useUserUrlCountQuery__
 *
 * To run a query within a React component, call `useUserUrlCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUrlCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUrlCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserUrlCountQuery(baseOptions?: Apollo.QueryHookOptions<UserUrlCountQuery, UserUrlCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserUrlCountQuery, UserUrlCountQueryVariables>(UserUrlCountDocument, options);
      }
export function useUserUrlCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserUrlCountQuery, UserUrlCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserUrlCountQuery, UserUrlCountQueryVariables>(UserUrlCountDocument, options);
        }
export type UserUrlCountQueryHookResult = ReturnType<typeof useUserUrlCountQuery>;
export type UserUrlCountLazyQueryHookResult = ReturnType<typeof useUserUrlCountLazyQuery>;
export type UserUrlCountQueryResult = Apollo.QueryResult<UserUrlCountQuery, UserUrlCountQueryVariables>;
export const MeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"self"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"permissions"}},{"kind":"Field","name":{"kind":"Name","value":"defaultCollectionsEnabled"}}]}},{"kind":"Field","name":{"kind":"Name","value":"userSettings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collectionId"}},{"kind":"Field","name":{"kind":"Name","value":"settingType"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"logout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"}}]}}]} as unknown as DocumentNode;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RequestAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"requestAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RequestAccountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type RequestAccountMutationFn = Apollo.MutationFunction<RequestAccountMutation, RequestAccountMutationVariables>;

/**
 * __useRequestAccountMutation__
 *
 * To run a mutation, you first call `useRequestAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccountMutation, { data, loading, error }] = useRequestAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestAccountMutation(baseOptions?: Apollo.MutationHookOptions<RequestAccountMutation, RequestAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestAccountMutation, RequestAccountMutationVariables>(RequestAccountDocument, options);
      }
export type RequestAccountMutationHookResult = ReturnType<typeof useRequestAccountMutation>;
export type RequestAccountMutationResult = Apollo.MutationResult<RequestAccountMutation>;
export type RequestAccountMutationOptions = Apollo.BaseMutationOptions<RequestAccountMutation, RequestAccountMutationVariables>;
export const ValidateAccountSetupTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"validateAccountSetupToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"TokenInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateAccountSetupToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;

/**
 * __useValidateAccountSetupTokenQuery__
 *
 * To run a query within a React component, call `useValidateAccountSetupTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAccountSetupTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateAccountSetupTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateAccountSetupTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateAccountSetupTokenQuery, ValidateAccountSetupTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateAccountSetupTokenQuery, ValidateAccountSetupTokenQueryVariables>(ValidateAccountSetupTokenDocument, options);
      }
export function useValidateAccountSetupTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateAccountSetupTokenQuery, ValidateAccountSetupTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateAccountSetupTokenQuery, ValidateAccountSetupTokenQueryVariables>(ValidateAccountSetupTokenDocument, options);
        }
export type ValidateAccountSetupTokenQueryHookResult = ReturnType<typeof useValidateAccountSetupTokenQuery>;
export type ValidateAccountSetupTokenLazyQueryHookResult = ReturnType<typeof useValidateAccountSetupTokenLazyQuery>;
export type ValidateAccountSetupTokenQueryResult = Apollo.QueryResult<ValidateAccountSetupTokenQuery, ValidateAccountSetupTokenQueryVariables>;
export const SetupAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"setupAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SetupAccountInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setupAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type SetupAccountMutationFn = Apollo.MutationFunction<SetupAccountMutation, SetupAccountMutationVariables>;

/**
 * __useSetupAccountMutation__
 *
 * To run a mutation, you first call `useSetupAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupAccountMutation, { data, loading, error }] = useSetupAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupAccountMutation(baseOptions?: Apollo.MutationHookOptions<SetupAccountMutation, SetupAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupAccountMutation, SetupAccountMutationVariables>(SetupAccountDocument, options);
      }
export type SetupAccountMutationHookResult = ReturnType<typeof useSetupAccountMutation>;
export type SetupAccountMutationResult = Apollo.MutationResult<SetupAccountMutation>;
export type SetupAccountMutationOptions = Apollo.BaseMutationOptions<SetupAccountMutation, SetupAccountMutationVariables>;
export const RequestPasswordResetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"requestPasswordReset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RequestPasswordResetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestPasswordReset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}]}]}}]} as unknown as DocumentNode;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"resetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetPasswordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ChangeEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"changeEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newEmail"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestEmailChange"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"newEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newEmail"}}}]}]}}]} as unknown as DocumentNode;
export type ChangeEmailMutationFn = Apollo.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      newEmail: // value for 'newEmail'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, options);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const ConfirmEmailChangeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"confirmEmailChange"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"confirmEmailChange"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode;
export type ConfirmEmailChangeMutationFn = Apollo.MutationFunction<ConfirmEmailChangeMutation, ConfirmEmailChangeMutationVariables>;

/**
 * __useConfirmEmailChangeMutation__
 *
 * To run a mutation, you first call `useConfirmEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailChangeMutation, { data, loading, error }] = useConfirmEmailChangeMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailChangeMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailChangeMutation, ConfirmEmailChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailChangeMutation, ConfirmEmailChangeMutationVariables>(ConfirmEmailChangeDocument, options);
      }
export type ConfirmEmailChangeMutationHookResult = ReturnType<typeof useConfirmEmailChangeMutation>;
export type ConfirmEmailChangeMutationResult = Apollo.MutationResult<ConfirmEmailChangeMutation>;
export type ConfirmEmailChangeMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailChangeMutation, ConfirmEmailChangeMutationVariables>;
export const ChangePasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"changePassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticatedPasswordChange"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const UserDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"userDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserDeleteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type UserDeleteMutationFn = Apollo.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, options);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const AdminUserDeleteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"adminUserDelete"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserDeleteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminUserDelete"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type AdminUserDeleteMutationFn = Apollo.MutationFunction<AdminUserDeleteMutation, AdminUserDeleteMutationVariables>;

/**
 * __useAdminUserDeleteMutation__
 *
 * To run a mutation, you first call `useAdminUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUserDeleteMutation, { data, loading, error }] = useAdminUserDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AdminUserDeleteMutation, AdminUserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUserDeleteMutation, AdminUserDeleteMutationVariables>(AdminUserDeleteDocument, options);
      }
export type AdminUserDeleteMutationHookResult = ReturnType<typeof useAdminUserDeleteMutation>;
export type AdminUserDeleteMutationResult = Apollo.MutationResult<AdminUserDeleteMutation>;
export type AdminUserDeleteMutationOptions = Apollo.BaseMutationOptions<AdminUserDeleteMutation, AdminUserDeleteMutationVariables>;
export const UserFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"userFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userFeedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type UserFeedbackMutationFn = Apollo.MutationFunction<UserFeedbackMutation, UserFeedbackMutationVariables>;

/**
 * __useUserFeedbackMutation__
 *
 * To run a mutation, you first call `useUserFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFeedbackMutation, { data, loading, error }] = useUserFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<UserFeedbackMutation, UserFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserFeedbackMutation, UserFeedbackMutationVariables>(UserFeedbackDocument, options);
      }
export type UserFeedbackMutationHookResult = ReturnType<typeof useUserFeedbackMutation>;
export type UserFeedbackMutationResult = Apollo.MutationResult<UserFeedbackMutation>;
export type UserFeedbackMutationOptions = Apollo.BaseMutationOptions<UserFeedbackMutation, UserFeedbackMutationVariables>;
export const AdminListUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"adminListUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AdminFindUsersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminListUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"permissions"}},{"kind":"Field","name":{"kind":"Name","value":"role"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useAdminListUsersQuery__
 *
 * To run a query within a React component, call `useAdminListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminListUsersQuery(baseOptions: Apollo.QueryHookOptions<AdminListUsersQuery, AdminListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminListUsersQuery, AdminListUsersQueryVariables>(AdminListUsersDocument, options);
      }
export function useAdminListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminListUsersQuery, AdminListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminListUsersQuery, AdminListUsersQueryVariables>(AdminListUsersDocument, options);
        }
export type AdminListUsersQueryHookResult = ReturnType<typeof useAdminListUsersQuery>;
export type AdminListUsersLazyQueryHookResult = ReturnType<typeof useAdminListUsersLazyQuery>;
export type AdminListUsersQueryResult = Apollo.QueryResult<AdminListUsersQuery, AdminListUsersQueryVariables>;
export const AddSettingDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"addSetting"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SettingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addSetting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"settingType"}}]}}]}}]} as unknown as DocumentNode;
export type AddSettingMutationFn = Apollo.MutationFunction<AddSettingMutation, AddSettingMutationVariables>;

/**
 * __useAddSettingMutation__
 *
 * To run a mutation, you first call `useAddSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSettingMutation, { data, loading, error }] = useAddSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSettingMutation(baseOptions?: Apollo.MutationHookOptions<AddSettingMutation, AddSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSettingMutation, AddSettingMutationVariables>(AddSettingDocument, options);
      }
export type AddSettingMutationHookResult = ReturnType<typeof useAddSettingMutation>;
export type AddSettingMutationResult = Apollo.MutationResult<AddSettingMutation>;
export type AddSettingMutationOptions = Apollo.BaseMutationOptions<AddSettingMutation, AddSettingMutationVariables>;
export const SettingRemoveDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"settingRemove"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SettingInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeSetting"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type SettingRemoveMutationFn = Apollo.MutationFunction<SettingRemoveMutation, SettingRemoveMutationVariables>;

/**
 * __useSettingRemoveMutation__
 *
 * To run a mutation, you first call `useSettingRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingRemoveMutation, { data, loading, error }] = useSettingRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettingRemoveMutation(baseOptions?: Apollo.MutationHookOptions<SettingRemoveMutation, SettingRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingRemoveMutation, SettingRemoveMutationVariables>(SettingRemoveDocument, options);
      }
export type SettingRemoveMutationHookResult = ReturnType<typeof useSettingRemoveMutation>;
export type SettingRemoveMutationResult = Apollo.MutationResult<SettingRemoveMutation>;
export type SettingRemoveMutationOptions = Apollo.BaseMutationOptions<SettingRemoveMutation, SettingRemoveMutationVariables>;
export const UpdateUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updateUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"defaultCollectionsEnabled"}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserPreferenceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userPreference"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userPreference"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"userPreferenceFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"userPreferenceFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserPreference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collapseMyCollections"}},{"kind":"Field","name":{"kind":"Name","value":"collapseFollowingCollections"}},{"kind":"Field","name":{"kind":"Name","value":"collectionLayout"}},{"kind":"Field","name":{"kind":"Name","value":"hideAddBookmarkHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCardManageTagsHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCardTodoMarkDoneHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCreateTagHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideWelcomeMessage"}}]}}]} as unknown as DocumentNode;

/**
 * __useUserPreferenceQuery__
 *
 * To run a query within a React component, call `useUserPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPreferenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPreferenceQuery(baseOptions?: Apollo.QueryHookOptions<UserPreferenceQuery, UserPreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPreferenceQuery, UserPreferenceQueryVariables>(UserPreferenceDocument, options);
      }
export function useUserPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPreferenceQuery, UserPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPreferenceQuery, UserPreferenceQueryVariables>(UserPreferenceDocument, options);
        }
export type UserPreferenceQueryHookResult = ReturnType<typeof useUserPreferenceQuery>;
export type UserPreferenceLazyQueryHookResult = ReturnType<typeof useUserPreferenceLazyQuery>;
export type UserPreferenceQueryResult = Apollo.QueryResult<UserPreferenceQuery, UserPreferenceQueryVariables>;
export const UpdatePreferenceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"updatePreference"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserPreferenceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updatePreference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"userPreferenceFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"userPreferenceFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserPreference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"collapseMyCollections"}},{"kind":"Field","name":{"kind":"Name","value":"collapseFollowingCollections"}},{"kind":"Field","name":{"kind":"Name","value":"collectionLayout"}},{"kind":"Field","name":{"kind":"Name","value":"hideAddBookmarkHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCardManageTagsHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCardTodoMarkDoneHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideCreateTagHint"}},{"kind":"Field","name":{"kind":"Name","value":"hideWelcomeMessage"}}]}}]} as unknown as DocumentNode;
export type UpdatePreferenceMutationFn = Apollo.MutationFunction<UpdatePreferenceMutation, UpdatePreferenceMutationVariables>;

/**
 * __useUpdatePreferenceMutation__
 *
 * To run a mutation, you first call `useUpdatePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferenceMutation, { data, loading, error }] = useUpdatePreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePreferenceMutation, UpdatePreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePreferenceMutation, UpdatePreferenceMutationVariables>(UpdatePreferenceDocument, options);
      }
export type UpdatePreferenceMutationHookResult = ReturnType<typeof useUpdatePreferenceMutation>;
export type UpdatePreferenceMutationResult = Apollo.MutationResult<UpdatePreferenceMutation>;
export type UpdatePreferenceMutationOptions = Apollo.BaseMutationOptions<UpdatePreferenceMutation, UpdatePreferenceMutationVariables>;
export const NotesQuotaDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"notesQuota"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"notesQuota"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maximum"}},{"kind":"Field","name":{"kind":"Name","value":"remaining"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useNotesQuotaQuery__
 *
 * To run a query within a React component, call `useNotesQuotaQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuotaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuotaQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotesQuotaQuery(baseOptions?: Apollo.QueryHookOptions<NotesQuotaQuery, NotesQuotaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotesQuotaQuery, NotesQuotaQueryVariables>(NotesQuotaDocument, options);
      }
export function useNotesQuotaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuotaQuery, NotesQuotaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotesQuotaQuery, NotesQuotaQueryVariables>(NotesQuotaDocument, options);
        }
export type NotesQuotaQueryHookResult = ReturnType<typeof useNotesQuotaQuery>;
export type NotesQuotaLazyQueryHookResult = ReturnType<typeof useNotesQuotaLazyQuery>;
export type NotesQuotaQueryResult = Apollo.QueryResult<NotesQuotaQuery, NotesQuotaQueryVariables>;
export const SharedCollectionsQuotaDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"sharedCollectionsQuota"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sharedCollectionsQuota"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"maximum"}},{"kind":"Field","name":{"kind":"Name","value":"remaining"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useSharedCollectionsQuotaQuery__
 *
 * To run a query within a React component, call `useSharedCollectionsQuotaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedCollectionsQuotaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedCollectionsQuotaQuery({
 *   variables: {
 *   },
 * });
 */
export function useSharedCollectionsQuotaQuery(baseOptions?: Apollo.QueryHookOptions<SharedCollectionsQuotaQuery, SharedCollectionsQuotaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharedCollectionsQuotaQuery, SharedCollectionsQuotaQueryVariables>(SharedCollectionsQuotaDocument, options);
      }
export function useSharedCollectionsQuotaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharedCollectionsQuotaQuery, SharedCollectionsQuotaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharedCollectionsQuotaQuery, SharedCollectionsQuotaQueryVariables>(SharedCollectionsQuotaDocument, options);
        }
export type SharedCollectionsQuotaQueryHookResult = ReturnType<typeof useSharedCollectionsQuotaQuery>;
export type SharedCollectionsQuotaLazyQueryHookResult = ReturnType<typeof useSharedCollectionsQuotaLazyQuery>;
export type SharedCollectionsQuotaQueryResult = Apollo.QueryResult<SharedCollectionsQuotaQuery, SharedCollectionsQuotaQueryVariables>;