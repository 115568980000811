// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { split } from '@apollo/client';
import { isBrowser } from '~/common/utilities/browser-utils';
import { isGQLSubscription, isGQLUsageEventCreateQuery } from '../filters';
import { httpBatchLink } from './httpBatchLink';
import { httpLink } from './httpLink';
import { wsLink } from './wsLink';

// http batch usage event mutations
// otherwise, use default links
const splitUsageHttpLink = split(
  isGQLUsageEventCreateQuery,
  httpBatchLink,
  httpLink,
);

// ApolloClient should only route requests via websocket
// when in the browser.
// See: https://github.com/apollographql/subscriptions-transport-ws/issues/333
const splitWsHttpLink = isBrowser
  ? split(isGQLSubscription, wsLink, splitUsageHttpLink)
  : splitUsageHttpLink;

export const splitLinks = splitWsHttpLink;
