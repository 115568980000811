// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { FieldFunctionOptions } from '@apollo/client/cache';
import {
  MutationUpdatePreferenceArgs,
  UpdatePreferenceMutation,
  UpdatePreferenceMutationVariables,
  UserPreferenceDocument,
} from '~/common/gql';

type TDocument = UpdatePreferenceMutation;
type TExisting = TDocument['updatePreference'];
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationUpdatePreferenceArgs;
type TVars = UpdatePreferenceMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 * - When mutating user preferences, update userPreferenceQuery.
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [existing, incoming, options] = args;
  const { cache, mergeObjects } = options;

  const documentNode = UserPreferenceDocument;

  cache.writeQuery({
    query: documentNode,
    overwrite: true,
    data: {
      userPreference: incoming,
    },
  });

  return mergeObjects(existing, incoming);
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
