// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

export const isGQLSubscription = ({ query }: Operation) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};

export const isGQLUsageEventCreateQuery = ({ query }: Operation) => {
  const definition = getMainDefinition(query);
  return (
    definition &&
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'mutation' &&
    definition.name &&
    definition.name.value === 'usageEventCreate'
  );
};
