// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Center,
  Heading,
  StyleProps,
  ToastId,
  useToast,
} from '@chakra-ui/react';
import { Toast } from '@niphtio/np-theme';
import React from 'react';

interface Props {
  message: React.ReactNode;
  duration?: number;
  maxToast?: number;
  then?: VoidFunction;
  styleProps?: StyleProps;
}

let toasts: ToastId[] = [];

export const useToastHelper = () => {
  const toast = useToast();

  const renderToastWith = (props?: Props) => {
    const {
      message,
      duration = 8000,
      then,
      maxToast = 2,
      styleProps,
    } = props || {};
    let toastId: ToastId;

    const close = () => {
      if (toastId) toast.close(toastId);
    };

    // if plain string then wrap it.
    const ToastMessage =
      typeof message === 'string' ? (
        <Center minH="full">
          <Heading fontSize="md" textAlign="center">
            {message}
          </Heading>
        </Center>
      ) : (
        message
      );

    return () => {
      if (toasts.length >= maxToast) {
        toast.close(toasts.shift());
      }

      toastId = toast({
        isClosable: true,
        position: 'bottom',
        containerStyle: {
          maxWidth: '100%',
          minW: 'auto',
          ...styleProps,
        },
        onCloseComplete() {
          toasts = toasts.filter((id) => id != toastId || !id);
        },
        duration,
        render: () => (
          <Toast onClick={close} cursor="pointer">
            {ToastMessage}
          </Toast>
        ),
      });

      toasts.push(toastId);

      then?.();
    };
  };

  return { renderToastWith };
};
