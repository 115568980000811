// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { FieldFunctionOptions } from '@apollo/client/cache';
import {
  CollectionDeleteMutation,
  CollectionDeleteMutationVariables,
  MutationCollectionDeleteArgs,
} from '~/common/gql';

type TDocument = CollectionDeleteMutation;
type TExisting = TDocument['collectionDelete'];
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationCollectionDeleteArgs;
type TVars = CollectionDeleteMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 * - Forces refetch of collection list queries by evicting relevant cached results
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [existing, incoming, options] = args;
  const { cache } = options;

  // evict cache to trigger collectionQuery refetch
  cache.evict({ fieldName: 'collectionQuery' });

  return incoming;
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
