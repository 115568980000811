// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { selectURI } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import omit from 'lodash/omit';
import { GQL_URL } from '~/common/config/runtime';
import { CLIENT_REQUEST_ID_HEADER_NAME } from '../../customHeaders';

const uri = GQL_URL;

export const httpBatchLink = new BatchHttpLink({
  credentials: 'include',
  headers: {},
  uri,
  batchMax: 10,
  batchInterval: 1000, // ms
  batchKey: (operation) => {
    const context = operation.getContext();

    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: {
        ...omit(context.headers, CLIENT_REQUEST_ID_HEADER_NAME),
      },
    };

    //may throw error if config not serializable
    return selectURI(operation, uri) + JSON.stringify(contextConfig);
  },
});
