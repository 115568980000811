// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Reference, StoreObject } from '@apollo/client';
import {
  ReadFieldFunction,
  ToReferenceFunction,
} from '@apollo/client/cache/core/types/common';
import { Order } from '~/common/gql';

export const insertItem = (
  itemRef: Reference | StoreObject,
  itemsRef: Array<Reference>,
  order: Order = Order.Desc,
  readField: ReadFieldFunction,
  toReference: ToReferenceFunction,
): Array<Reference> => {
  const itemId = readField('id', itemRef);
  const isExisting = itemsRef.find((it) => itemId === readField('id', it));
  if (isExisting) {
    return itemsRef;
  }
  // in some case itemRef may be a store object
  // so we force the item to be a reference
  const forceItemReference = toReference(itemRef);
  return [forceItemReference, ...itemsRef].sort((refA, refB) => {
    const a = Number(readField('id', refA));
    const b = Number(readField('id', refB));
    return order === Order.Asc ? a - b : b - a;
  });
};
