// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { FieldFunctionOptions } from '@apollo/client/cache';
import {
  MutationRemoveItemFromCollectionArgs,
  RemoveItemFromCollectionMutation,
  RemoveItemFromCollectionMutationVariables,
} from '~/common/gql';
import { itemsQueryPaginatedModifier } from '../helpers/itemsQueryPaginated/itemsQueryPaginatedModifier';

type TDocument = RemoveItemFromCollectionMutation;
type TExisting = TDocument['removeItemFromCollection'];
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationRemoveItemFromCollectionArgs;
type TVars = RemoveItemFromCollectionMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 * - Removes item from item list queries requesting only items from affected collection
 * - If item has no tags after it's removed from a collection,
 *   forces a refetch on item list queries requesting only orphans
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [existing, incoming, options] = args;
  const {
    args: {
      input: {
        find: { id: itemId, collectionId },
      },
    },
    cache,
    readField,
    toReference,
    mergeObjects,
  } = options;

  const itemRef = toReference(`Item:${itemId}`);

  cache.modify({
    id: cache.identify(itemRef),
    fields: {
      collection: (...args) => {
        const [collectionItems] = args;
        return collectionItems?.filter(
          (it) => it.collectionId !== collectionId,
        );
      },
    },
  });

  cache.modify({
    fields: {
      itemsQueryPaginated: itemsQueryPaginatedModifier(itemRef, {}),
    },
  });

  return incoming;
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
