// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

export enum ActionType {
  /* general */
  LOGGED_IN = 'LOGGED_IN',
  OPEN_VIEW = 'OPEN_VIEW',
  CLOSE_VIEW = 'CLOSE_VIEW',
  DETECTED_CLICK = 'DETECTED_CLICK',
  DETECTED_SCROLL_DOWN = 'DETECTED_SCROLL_DOWN',
  DETECTED_SCROLL_UP = 'DETECTED_SCROLL_UP',
  DETECTED_SCROLL_UNKNOWN = 'DETECTED_SCROLL_UNKNOWN',
  LOADING_PREV = 'LOADING_PREV',
  LOADING_NEXT = 'LOADING_NEXT',
  OPENED_MAIN_NAVIGATION_MENU = 'OPENED_MAIN_NAVIGATION_MENU',
  CLOSED_MAIN_NAVIGATION_MENU = 'CLOSED_MAIN_NAVIGATION_MENU',

  /* app events */
  INSTALLED = 'INSTALLED',
  /* sign up */
  REQUEST_ACCOUNT = 'REQUEST_ACCOUNT',
  ACCOUNT_REQUESTED = 'ACCOUNT_REQUESTED',
  /* reader events */
  OPEN_BOOKMARK = 'OPEN_BOOKMARK',
  NEXT_BOOKMARK = 'NEXT_BOOKMARK',
  PREVIOUS_BOOKMARK = 'PREVIOUS_BOOKMARK',
  /* bookmarking events */
  ADD_BOOKMARK = 'ADD_BOOKMARK',
  BOOKMARK_ADDED = 'BOOKMARK_ADDED',
  UPDATE_ITEM = 'UPDATE_ITEM',
  ITEM_UPDATED = 'ITEM_UPDATED',
  MOVE_BOOKMARK = 'MOVE_BOOKMARK',
  TRASH_BOOKMARK = 'TRASH_BOOKMARK',
  BOOKMARK_TRASHED = 'BOOKMARK_TRASHED',
  UNTRASH_BOOKMARK = 'UNTRASH_BOOKMARK',
  BOOKMARK_UNTRASHED = 'BOOKMARK_UNTRASHED',
  DELETE_BOOKMARK = 'DELETE_BOOKMARK',
  BOOKMARK_DELETED = 'BOOKMARK_DELETED',
  /* section events */
  ADD_SECTION = 'ADD_SECTION',
  SECTION_ADDED = 'SECTION_ADDED',
  UPDATE_SECTION = 'UPDATE_SECTION',
  SECTION_UPDATED = 'SECTION_UPDATED',
  DELETE_SECTION = 'DELETE_SECTION',
  SECTION_DELETED = 'SECTION_DELETED',
  /* collection events */
  ADD_COLLECTION = 'ADD_COLLECTION',
  COLLECTION_ADDED = 'COLLECTION_ADDED',
  UPDATE_COLLECTION = 'UPDATE_COLLECTION',
  COLLECTION_UPDATED = 'COLLECTION_UPDATED',
  DELETE_COLLECTION = 'DELETE_COLLECTION',
  COLLECTION_DELETED = 'COLLECTION_DELETED',
  /* todo events */
  OPEN_ITEM_WITH_TODO = 'OPEN_ITEM_WITH_TODO',
  OPENED_ITEM_WITH_TODO = 'OPENED_ITEM_WITH_TODO',
  CREATE_TODO_ITEM = 'CREATE_TODO_ITEM',
  TODO_ITEM_CREATED = 'TODO_ITEM_CREATED',
  UPDATE_TODO_ITEM = 'UPDATE_TODO_ITEM',
  TODO_ITEM_UPDATED = 'TODO_ITEM_UPDATED',
  DELETE_TODO = 'DELETE_TODO',
  TODO_DELETED = 'TODO_DELETED',
  /* search events */
  OPEN_SEARCH = 'OPEN_SEARCH',
  CLOSE_SEARCH = 'CLOSE_SEARCH',
  /* notes events */
  MODIFY_NOTE_IN_APP = 'MODIFY_NOTE_IN_APP',
  OPEN_NOTE_EDITOR = 'OPEN_NOTE_EDITOR',
  OPEN_ITEM_WITH_NOTE = 'OPEN_ITEM_WITH_NOTE',
  VIEW_NOTE_TAB = 'VIEW_NOTE_TAB',
  DELETED_NOTE_IN_APP = 'DELETED_NOTE_IN_APP',
  /* item events */
  CLICK_ITEM_URL = 'CLICK_ITEM_URL',
}
