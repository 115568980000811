// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldPolicy,
} from '@apollo/client';
import {
  KeyArgsFunction,
  KeySpecifier,
} from '@apollo/client/cache/inmemory/policies';
import {
  ItemSearchQuery,
  ItemSearchQueryVariables,
  QueryItemSearchArgs,
} from '~/common/gql';
import mergeOverlappingSequences from '~/common/utilities/array-utils/mergeOverlappingSequences';
import { getIdFromItem } from './helpers/helpers';

type TDocument = ItemSearchQuery;
type TExisting = TDocument['itemSearch'];
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = QueryItemSearchArgs;
type TVars = ItemSearchQueryVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TKeyArgs = KeySpecifier | KeyArgsFunction | false;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

const keyArgs: TKeyArgs = (args) => {
  const {
    input: { query },
  } = args as TArg;

  const key: TArg = {
    input: {
      query,
    },
  };

  return JSON.stringify(key);
};

const merge: TMergeFunction = (existing, incoming, { readField }) => {
  const metadata = {
    hasMore: incoming?.hasMore ?? false,
    continuation: incoming?.continuation,
  };

  const merged = mergeOverlappingSequences({
    preferStart: false,
    startsWith: existing?.items ?? [],
    endsWith: incoming.items,
    equals: (a, b) =>
      getIdFromItem(a, readField) === getIdFromItem(b, readField),
  });

  // if they don't overlap, then just append
  const nextItems = merged ?? [
    ...(existing?.items ?? []),
    ...(incoming?.items ?? []),
  ];

  return {
    ...metadata,
    items: nextItems,
  };
};

export default {
  keyArgs,
  merge,
} satisfies TPolicyInternal as TPolicy;
