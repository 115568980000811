// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { isBrowser } from '~/common/utilities/browser-utils';
import { ActionType, onScrollDebounced } from '~/common/utilities/usage-event';
import { getUsageAttributes } from '~/common/utilities/usage-event/getUsageAttributes';
import { useUsageEventCreateMutationHelper } from '~/hooks';

export const useTrackUsage = () => {
  const { logUsageEvent } = useUsageEventCreateMutationHelper();
  const router = useRouter();
  const [path, setPath] = useState<string>();

  useEffect(() => {
    if (isBrowser) {
      // prevents errors on unit test

      if (path != location.pathname) {
        logUsageEvent({
          actionType: ActionType.OPEN_VIEW,
        });
        setPath(location.pathname);
      }
    }
  }, [logUsageEvent, path, router.asPath]);

  useEffect(() => {
    const onScroll = onScrollDebounced(({ direction }) => {
      logUsageEvent({
        actionType:
          direction === 'down'
            ? ActionType.DETECTED_SCROLL_DOWN
            : direction === 'up'
              ? ActionType.DETECTED_SCROLL_UP
              : ActionType.DETECTED_SCROLL_UNKNOWN,
      });
    });

    const onClick = (event: MouseEvent) => {
      const { context, label, text, metadata } = getUsageAttributes(event);

      logUsageEvent({
        actionType: ActionType.DETECTED_CLICK,
        context,
        label,
        text,
        metadata,
      });
    };

    document.addEventListener('scroll', onScroll);
    document.addEventListener('click', onClick, true);

    return () => {
      document.removeEventListener('scroll', onScroll);
      document.removeEventListener('click', onClick, true);
    };
  }, [logUsageEvent]);

  return {};
};
