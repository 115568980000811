// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import first from 'lodash/first';
import last from 'lodash/last';
import { useState } from 'react';
import { Order } from '~/common/gql';
import { ActionType } from '~/common/utilities/usage-event';
import { useUsageEventCreateMutationHelper } from './useUsageEventCreateMutationHelper';

type TypeWithId = { id: number; [key: string]: any };

interface PagingProps {
  itemIds?: TypeWithId[];
  options: {
    [key: string]: any;
  };
  fetchMore: any;
}

export const useItemsPagingHelper = ({
  itemIds,
  options,
  fetchMore,
}: PagingProps) => {
  const { logUsageEvent } = useUsageEventCreateMutationHelper();
  const [pagingError, setPagingError] = useState();

  // Gets the next page
  const nextPage = async () => {
    // Use the last item in the list to grab more pages
    const before = last(itemIds);

    if (!before) return;

    logUsageEvent({
      actionType: ActionType.LOADING_NEXT,
    });

    try {
      const request = {
        ...options,
        variables: {
          ...options.variables,
          pagination: {
            ...options.variables.pagination,
            before,
          },
        },
        notifyOnNetworkStatusChange: true, // required so that loading state updates and notifies infininte scroll component
      };

      await fetchMore(request);
      setPagingError(undefined);
    } catch (e: any) {
      setPagingError(e);
    }
  };

  // Gets the prev page
  const prevPage = async () => {
    // Use the first item in the list to grab more pages
    const after = first(itemIds);

    if (!after) return;

    logUsageEvent({
      actionType: ActionType.LOADING_PREV,
    });

    try {
      const request = {
        ...options,
        variables: {
          ...options.variables,
          pagination: {
            ...options.variables.pagination,
            order:
              options.variables.pagination.order !== Order.Desc
                ? Order.Desc
                : Order.Asc, // reverse the order
            after,
            inclusive: true, // required so that merge knows where to concat incoming with existing results
          },
        },
        notifyOnNetworkStatusChange: true, // required so that loading state updates and notifies infininte scroll component
      };

      await fetchMore(request);
      setPagingError(undefined);
    } catch (e: any) {
      setPagingError(e);
    }
  };

  return {
    nextPage,
    prevPage,
    pagingError,
  };
};
