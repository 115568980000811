// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { withItemsQueryVariablesFn } from '~/common/cache/helpers/itemsQueryPaginated/types/withItemsQueryVariablesFn';

/**
 *
 * @param it
 * @returns true if query only returns items that have no collections
 */
export const getIsAnyOrphanItemsQueryVariables: withItemsQueryVariablesFn = (
  it,
) => {
  return (
    it &&
    it.variables &&
    it.variables.input &&
    it.variables.input.orphansOnly === true // is orphans only
  );
};
