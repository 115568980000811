// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import reject from 'lodash/reject';
import { UsageEventCreateInput } from '~/common/gql';
import { ActionType } from './actionType';
import { LogUsageEventInputMetadata } from './LogUsageEventInput';

class UsageEventNotDefinedError extends Error {
  constructor(actionType: ActionType) {
    super(`Usage event message is not defined for ${actionType}.`);
    this.name = 'UsageEventNotDefinedError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UsageEventNotDefinedError);
    }
  }
}

const firstNotEmpty = (...arr: string[]) => {
  return first(reject(arr, isEmpty));
};

const formatLabel = (label: string) => {
  if (isEmpty(label)) return;
  return `"${label}"`;
};

const getLabel = (target: HTMLElement) => {
  switch (target.localName) {
    case 'button':
      return firstNotEmpty(target.ariaLabel, target.innerText);
    case 'input':
      return firstNotEmpty(
        (target as HTMLInputElement).placeholder,
        target.ariaLabel,
      );
    case 'textarea':
      return firstNotEmpty(
        (target as HTMLTextAreaElement).placeholder,
        target.ariaLabel,
      );
    default:
      return target.ariaLabel;
  }
};

export const getUsageEventMessage = (
  input: UsageEventCreateInput,
  metadata: LogUsageEventInputMetadata,
): string => {
  switch (input.actionType) {
    case ActionType.LOGGED_IN:
      throw new UsageEventNotDefinedError(ActionType.LOGGED_IN);
    case ActionType.OPEN_VIEW:
      return `${input.srcPath}: Opened view.`;
    case ActionType.CLOSE_VIEW:
      return `${input.srcPath}: Closed view.`;
    case ActionType.DETECTED_CLICK:
      const target = metadata.target;
      const chakraClass = [...target.classList].find((it) =>
        it.startsWith('chakra-'),
      );
      const cls = chakraClass ? `.${chakraClass}` : '';
      const selector = `${target.localName}${cls}`;
      const label = formatLabel(getLabel(target));
      return (
        reject([`${input.srcPath}: Clicked`, selector, label], isNil).join(
          ' ',
        ) + '.'
      );
    case ActionType.DETECTED_SCROLL_DOWN:
      return `${input.srcPath}: Scrolled down.`;
    case ActionType.DETECTED_SCROLL_UP:
      return `${input.srcPath}: Scrolled up.`;
    case ActionType.DETECTED_SCROLL_UNKNOWN:
      return `${input.srcPath}: Scrolled.`;
    case ActionType.LOADING_PREV:
      return `${input.srcPath}: Requested previous results.`;
    case ActionType.LOADING_NEXT:
      return `${input.srcPath}: Requested next results.`;
    case ActionType.OPENED_MAIN_NAVIGATION_MENU:
      return `${input.srcPath}: Opened main navigation menu.`;
    case ActionType.CLOSED_MAIN_NAVIGATION_MENU:
      return `${input.srcPath}: Closed main navigation menu.`;
    /* app events */
    case ActionType.INSTALLED:
      throw new UsageEventNotDefinedError(ActionType.INSTALLED);
    /* sign up */
    case ActionType.REQUEST_ACCOUNT:
      return `${input.srcPath}: Requested an account: pending.`;
    case ActionType.ACCOUNT_REQUESTED:
      return `${input.srcPath}: Requested an account: succeeded.`;
    /* reader events */
    case ActionType.OPEN_BOOKMARK:
      throw new UsageEventNotDefinedError(ActionType.OPEN_BOOKMARK);
    case ActionType.NEXT_BOOKMARK:
      return `${input.srcPath}: Get next item in reader.`;
    case ActionType.PREVIOUS_BOOKMARK:
      return `${input.srcPath}: Get previous item in reader.`;
    /* bookmarking events */
    case ActionType.ADD_BOOKMARK:
      return `${input.srcPath}: Add a bookmark: pending.`;
    case ActionType.BOOKMARK_ADDED:
      return `${input.srcPath}: Add a bookmark: succeeded.`;
    case ActionType.UPDATE_ITEM:
      return `${input.srcPath}: Update item: pending.`;
    case ActionType.ITEM_UPDATED:
      return `${input.srcPath}: Update item: succeeded.`;
    case ActionType.MOVE_BOOKMARK:
      throw new UsageEventNotDefinedError(ActionType.MOVE_BOOKMARK);
    case ActionType.TRASH_BOOKMARK:
      return `${input.srcPath}: Trash bookmark: pending.`;
    case ActionType.BOOKMARK_TRASHED:
      return `${input.srcPath}: Trash bookmark: succeeded.`;
    case ActionType.UNTRASH_BOOKMARK:
      return `${input.srcPath}: Untrash bookmark: pending.`;
    case ActionType.BOOKMARK_UNTRASHED:
      return `${input.srcPath}: Untrash bookmark: succeeded.`;
    case ActionType.DELETE_BOOKMARK:
      return `${input.srcPath}: Permanently delete a bookmark: pending.`;
    case ActionType.BOOKMARK_DELETED:
      return `${input.srcPath}: Permanently delete a bookmark: succeeded.`;
    /* collection events */
    case ActionType.ADD_COLLECTION:
      return `${input.srcPath}: Create a collection: pending.`;
    case ActionType.COLLECTION_ADDED:
      return `${input.srcPath}: Create a collection: succeeded.`;
    case ActionType.UPDATE_COLLECTION:
      return `${input.srcPath}: Rename collection: pending.`;
    case ActionType.COLLECTION_UPDATED:
      return `${input.srcPath}: Rename collection: succeeded.`;
    case ActionType.DELETE_COLLECTION:
      return `${input.srcPath}: Delete collection: pending.`;
    case ActionType.COLLECTION_DELETED:
      return `${input.srcPath}: Delete collection: succeeded.`;
    /* todo events */
    case ActionType.OPEN_ITEM_WITH_TODO:
      return `${input.srcPath}: Open item with to do.`;
    case ActionType.OPENED_ITEM_WITH_TODO:
      return `${input.srcPath}: Opened item with to do.`;
    case ActionType.CREATE_TODO_ITEM:
      return `${input.srcPath}: Add to do: pending.`;
    case ActionType.TODO_ITEM_CREATED:
      return `${input.srcPath}: Add to do: succeeded.`;
    case ActionType.UPDATE_TODO_ITEM:
      return `${input.srcPath}: Mark to do "${input.text}": pending.`;
    case ActionType.TODO_ITEM_UPDATED:
      return `${input.srcPath}: Mark to do "${input.text}": succeeded.`;
    case ActionType.DELETE_TODO:
      return `${input.srcPath}: Delete to do: pending.`;
    case ActionType.TODO_DELETED:
      return `${input.srcPath}: Delete to do: succeeded.`;
    /* search events */
    case ActionType.OPEN_SEARCH:
      return `${input.srcPath}: Opened search.`;
    case ActionType.CLOSE_SEARCH:
      return `${input.srcPath}: Closed search.`;
    /* notes events */
    case ActionType.MODIFY_NOTE_IN_APP:
      return `${input.srcPath}: Saving note.`;
    case ActionType.OPEN_NOTE_EDITOR:
      return `${input.srcPath}: Opened note editor.`;
    case ActionType.OPEN_ITEM_WITH_NOTE:
      return `${input.srcPath}: Opened item with note.`;
    case ActionType.VIEW_NOTE_TAB:
      return `${input.srcPath}: View note "${input.srcPath}: "`;
    case ActionType.DELETED_NOTE_IN_APP:
      return `${input.srcPath}: Deleted note "${input.srcPath}: "`;
    case ActionType.CLICK_ITEM_URL:
      return (() => {
        const target = metadata.target;
        const chakraClass = [...(target?.classList ?? [])].find((it) =>
          it.startsWith('chakra-'),
        );
        const cls = chakraClass ? `.${chakraClass}` : '';
        const selector = `${target.localName}${cls}`;
        const label = formatLabel(getLabel(target));
        return (
          reject(
            [`${input.srcPath}: Clicked item url`, selector, label],
            isNil,
          ).join(' ') + '.'
        );
      })();

    default:
      throw new UsageEventNotDefinedError(input.actionType as ActionType);
  }
};
