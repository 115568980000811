// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

/**
 * Recursively traverses up the dom tree starting at element
 * until it finds an element that has defined any property in `properties`.
 *
 * @param element    element to start traversal at
 * @param properties list of properties to look for
 * @returns
 */
export const getElementAncestorWithNonEmptyProps = (
  element,
  properties,
): HTMLElement => {
  if (!element) {
    return undefined;
  }

  const hasNonEmptyProp = some(properties.map((it) => !isEmpty(element[it])));

  if (hasNonEmptyProp) {
    return element;
  }

  return getElementAncestorWithNonEmptyProps(element.parentElement, properties);
};
