// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Reference } from '@apollo/client/cache';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import first from 'lodash/first';
import isNil from 'lodash/isNil';

export type GetIdentifierFunction = (
  itemRef: Reference,
  readField: ReadFieldFunction,
) => number;

export const getIdFromItem: GetIdentifierFunction = (itemRef, readField) => {
  return readField('id', itemRef);
};

export const getTodoIdFromItem: GetIdentifierFunction = (
  itemRef,
  readField,
) => {
  const todos = readField('todo', itemRef) as [Reference];
  const todo = first(todos);

  if (isNil(todo)) return;

  const id = readField('id', todo) as number;

  return id;
};
