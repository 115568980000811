// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { first } from 'lodash';
import URI from 'urijs';
import { useItemCreateMutation } from '~/common/gql/item.generated';
import { ActionType } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

const constructUri = (url: string): string => {
  let uri = URI(url);
  const protocol = uri.protocol();

  if (protocol != '' && protocol != 'http' && protocol != 'https') {
    throw new Error(`URL not valid.`);
  }

  if (protocol == '') {
    uri.protocol('https');
    // make relative url absolute
    uri = uri.toString().replace(':///', '://');
  } else {
    uri = uri.toString();
  }

  return uri;
};

export const useItemCreateQueryHelper = createMutationHelperHook(
  useItemCreateMutation,
  {
    errorMessage: 'There was an error creating this item',
    transformVariables: (variables) => ({
      ...variables,
      url: constructUri(variables.input.url),
    }),
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.ADD_BOOKMARK,
        srcCollectionId:
          variables.input.collectionId ?? first(variables.input.collectionIds),
      }),
      afterMutation: (_, data) => ({
        actionType: ActionType.BOOKMARK_ADDED,
        itemId: data?.itemCreate?.id,
        srcCollectionId: first(data?.itemCreate.collection)?.collectionId,
      }),
    },
  },
);
