// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import { SyntheticEvent } from 'react';
import { getElementAncestorWithNonEmptyProps } from '~/common/utilities/html-element/getElementAncestorWithNonEmptyProps';
import { LogUsageEventInput } from './LogUsageEventInput';

export const getUsageAttributes = (
  event: Event | SyntheticEvent<any>,
): Pick<LogUsageEventInput, 'context' | 'label' | 'text' | 'metadata'> => {
  const target = event.target as HTMLElement;
  const context = [...target.classList].find((it) => it.startsWith('chakra-'));

  const infoElement = getElementAncestorWithNonEmptyProps(target, [
    'ariaLabel',
    'innerText',
    'placeholder',
  ]);

  const hrefElement = getElementAncestorWithNonEmptyProps(target, [
    'href',
  ]) as HTMLAnchorElement;

  const text = !isEmpty(infoElement?.innerText)
    ? infoElement?.innerText
    : infoElement?.ariaLabel;
  const label = hrefElement?.href ?? infoElement?.localName;

  return {
    context,
    label,
    text,
    metadata: {
      target: infoElement,
    },
  };
};
