// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Reference, StoreObject } from '@apollo/client';
import { ItemsQueryStoreFieldNameVariables } from '../types/ItemsQueryStoreFieldNameVariables';
import { ModifierWithParsedQuery } from '../types/ModifierWithParsedQuery';
import { insertItem } from './insertItem';

export const getInsertItemModifier =
  (
    itemRef: Reference | StoreObject,
  ): ModifierWithParsedQuery<ItemsQueryStoreFieldNameVariables> =>
  (...args) => {
    const [parsedQuery, value, details] = args;
    return {
      ...value,
      items: insertItem(
        itemRef,
        value['items'],
        parsedQuery.variables?.pagination?.order,
        details.readField,
        details.toReference,
      ),
    };
  };
