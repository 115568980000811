// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { InMemoryCache } from '@apollo/client';
import addTagToItem from './policies/addTagToItem';
import collectionCreate from './policies/collectionCreate';
import collectionDelete from './policies/collectionDelete';
import itemCreate from './policies/itemCreate';
import itemDelete from './policies/itemDelete';
import itemSearch from './policies/itemSearch';
import itemsQueryPaginated from './policies/itemsQueryPaginated';
import itemUpdate from './policies/itemUpdate';
import removeItemFromCollection from './policies/removeItemFromCollection';
import sectionPreferenceUpdate from './policies/sectionPreferenceUpdate';
import sharedItemQuery from './policies/sharedItemQuery';
import todoItemCreate from './policies/todoItemCreate';
import todoItemDelete from './policies/todoItemDelete';
import todoItemUpdate from './policies/todoItemUpdate';
import updatePreference from './policies/updatePreference';

interface Props {
  remoteState?: any;
}

export const initCache = ({ remoteState }: Props) => {
  const cache = new InMemoryCache({
    typePolicies: {
      Item: {
        fields: {
          collection: {
            merge: (...args) => {
              const [, incoming] = args;
              return incoming;
            },
          },
        },
      },
      Query: {
        fields: {
          itemsQueryPaginated,
          itemSearch,
          sharedItemQuery,
        },
      },
      Mutation: {
        fields: {
          addTagToItem,
          collectionCreate,
          collectionDelete,
          itemCreate,
          itemDelete,
          itemUpdate,
          removeItemFromCollection,
          sectionPreferenceUpdate,
          todoItemCreate,
          todoItemUpdate,
          todoItemDelete,
          updatePreference,
        },
      },
    },
  }).restore(remoteState || {});

  return cache;
};
