// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import isNil from 'lodash/isNil';
import { Item } from '~/common/gql';

export const getIsItemTrashed = (
  itemRef: Reference,
  readField: ReadFieldFunction,
) => {
  const trashedAt = readField('trashedAt', itemRef) as Item['trashedAt'];
  return !isNil(trashedAt);
};
