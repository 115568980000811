// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import first from 'lodash/first';
import isNil from 'lodash/isNil';

export const getIsItemTodoMarkedDone = (
  itemRef: Reference,
  readField: ReadFieldFunction,
) => {
  const todoItems = readField('todo', itemRef) as Reference[];
  const todoItem = first(todoItems);
  const markedDoneAt = readField('markedDoneAt', todoItem);
  return !isNil(markedDoneAt);
};
