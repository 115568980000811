// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Reference, StoreObject } from '@apollo/client';
import { ModifierWithParsedQuery } from '../types/ModifierWithParsedQuery';
import { removeItem } from './removeItem';

export const getRemoveItemModifier =
  (itemRef: Reference | StoreObject): ModifierWithParsedQuery<any> =>
  (...args) => {
    const [parsedQuery, value, details] = args;
    return {
      ...value,
      items: removeItem(itemRef, value['items'], details.readField),
    };
  };
