// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { FieldFunctionOptions, Reference } from '@apollo/client/cache';
import {
  ItemCreateMutation,
  ItemCreateMutationVariables,
  MutationTodoItemCreateArgs,
} from '~/common/gql';
import { itemsQueryPaginatedModifier } from '../helpers/itemsQueryPaginated/itemsQueryPaginatedModifier';

type TDocument = ItemCreateMutation;
type TExisting = Reference;
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationTodoItemCreateArgs;
type TVars = ItemCreateMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [existing, incoming, options] = args;
  const { variables, cache, readField, mergeObjects } = options;
  const itemRef = incoming;

  cache.modify({
    fields: {
      itemsQueryPaginated: itemsQueryPaginatedModifier(itemRef, {}),
    },
  });

  // forces itemSearch query to do a network fetch
  cache.evict({ fieldName: 'itemSearch' });

  return mergeObjects(existing, incoming);
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
