// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { withItemsQueryVariablesFn } from '~/common/cache/helpers/itemsQueryPaginated/types/withItemsQueryVariablesFn';

/**
 *
 * @param it
 * @returns true if query returns only items with to dos regardless of whether they're marked done or not done
 */
export const getIsAnyTodoItemsQueryVariables: withItemsQueryVariablesFn = (
  it,
) => {
  return (
    it &&
    it.variables &&
    it.variables.input &&
    it.variables.input.todosOnly === true
  );
};
