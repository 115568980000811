// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldPolicy,
  FieldReadFunction,
  Reference,
} from '@apollo/client';
import {
  QuerySharedItemQueryArgs,
  SharedItemQuery,
  SharedItemQueryVariables,
} from '~/common/gql';

type TDocument = SharedItemQuery;
type TExisting = Reference;
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = QuerySharedItemQueryArgs;
type TVars = SharedItemQueryVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TReadFunction = FieldReadFunction<TExisting, TReadResult, TOptions>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

const read: TReadFunction = (...props) => {
  const [existing, options] = props;
  const { args, toReference } = options;
  if (existing) {
    return existing;
  }
  // fetch from normalized items
  // for example: if the sharedItems query contains the item
  // then we can return results from the sharedItems query
  const itemId = args?.input?.itemId;
  if (!itemId) {
    return existing;
  }
  return toReference({
    __typename: 'Item',
    id: itemId,
  }) as unknown as TReadResult; /**
  --
  This is a workaround.
  --
  Reference is not compatible with TReadResult, but it will be
  resolved to TReadResult by the cache. 
  We can't set TExisting to Reference because we want type 
  checking while we're editing the read function.
   */
};

export default {
  read,
} satisfies TPolicyInternal as TPolicy;
