// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import isNil from 'lodash/isNil';
import { Item } from '~/common/gql';
import { parseStoreFieldName } from '../parseStoreFieldName/parseStoreFieldName';
import { getParsedItemsQueryInfo } from './getParsedItemsQueryInfo';
import { ItemsQueryStoreFieldNameVariables } from './types/ItemsQueryStoreFieldNameVariables';

interface Props {
  item: {
    isOrphan: boolean;
    isTodo: boolean;
    isTodoMarkedDone: boolean;
    isTrashed: boolean;
    collections: Item['collection'];
  };
  query: {
    storeFieldName: string;
  };
}

export const getIsItemShouldBeIncludedInItemsQuery = ({
  item,
  query,
}: Props) => {
  const parsedQuery = parseStoreFieldName<ItemsQueryStoreFieldNameVariables>(
    query.storeFieldName,
  );
  const queryInfo = getParsedItemsQueryInfo(parsedQuery);

  // check for collection match
  const isCollectionMatch =
    !queryInfo.isAnyCollectionItemsQuery || // if is not a collection specific query
    (queryInfo.isAnyCollectionItemsQuery && // ...or if the queried collection is an item's collection
      !isNil(
        item.collections.find(
          (it) =>
            Number(it.collectionId) ===
            Number(parsedQuery.variables.input.collectionId),
        ),
      ));
  // check for orphan match
  const isOrphanMatch =
    !queryInfo.isAnyOrphanItemsQuery || // if not only orphans
    (queryInfo.isAnyOrphanItemsQuery && item.isOrphan); // ...or if only orphans and item is an orphan
  // check for todo match
  const isTodoMatch =
    !queryInfo.isAnyTodoItemsQuery || // if not only todo items
    (queryInfo.isAnyTodoItemsQuery &&
      item.isTodo && // if it is only todo items, then check if item is a todo
      (isNil(parsedQuery.variables.input.todoOptions?.markedDone) || // ...and check that it's any to do
        parsedQuery.variables.input.todoOptions?.markedDone ==
          item.isTodoMarkedDone)); // ...or query todo status matches item todo status
  // check for trashedness match
  const isTrashMatch =
    (queryInfo.isAnyTrashItemsQuery && item.isTrashed) || // is only trashed and item is trashed
    (!queryInfo.isAnyTrashItemsQuery && !item.isTrashed); // is not trashed and item is not trashed

  return isCollectionMatch && isOrphanMatch && isTodoMatch && isTrashMatch;
};
