// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { onError } from '@apollo/client/link/error';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { recursivelyRedactObject } from '~/common/utilities/object-utils/recursivelyRedactObject';
import { datadog } from '~/lib/datadog/datadog-logger';
import { redactInput } from '../redactInput';

/**
 * Logs errors to Datadog.
 */
export const logErrorsToDatadogLink = onError((errorResponse) => {
  const { graphQLErrors, networkError, operation } = errorResponse;
  const context = operation.getContext();
  const redactedOperation = recursivelyRedactObject(operation, redactInput);
  const request_id = context.response?.headers?.get('x-amz-cf-id');
  const request = {
    headers: {
      ...context.headers,
    },
    body: {
      ...redactedOperation,
    },
  };

  const response = {
    headers: context.response?.headers,
    bodyUsed: context.response?.bodyUsed,
    ok: context.response?.ok,
    redirected: context.response?.redirected,
    status: context.response?.status,
    statusText: context.response?.statusText,
    type: context.response?.type,
    url: context.response?.url,
    body: errorResponse.response,
  };

  const operationName = operation.operationName;
  const operationType = (first(operation.query.definitions) as any).operation;
  const noResponse = isNil(errorResponse.response);
  const actionable =
    errorResponse.response?.errors?.some(
      (it) => it.extensions?.actionable === true,
    ) ?? false;

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const log = datadog.generateLog({
        type: '[GQL Error]',
        message: `${operationType} ${operationName}`,
        context: {
          http: {
            request_id,
          },
          niphtio: {
            request,
            response,
          },
        },
        error: error as any,
      });

      datadog.logger.then((logger) => {
        const isNotAuthenticatedError = !isEmpty(
          response?.body?.errors?.filter(
            (it) => it.message === 'Not authenticated',
          ),
        );
        if (actionable) {
          logger.error(...log);
        } else if (noResponse) {
          logger.warn(...log);
        } else {
          logger.info(...log);
        }
      });
    });
  }

  if (networkError) {
    const log = datadog.generateLog({
      type: '[Network Error]',
      message: `${operationType} ${operationName}`,
      context: {
        http: {
          request_id,
        },
        niphtio: {
          request,
          response,
        },
      },
      error: networkError,
    });

    datadog.logger.then((logger) => {
      if (response.status >= 500 || actionable) {
        // report 500 and actionable errors
        logger.error(...log);
      } else if (noResponse) {
        // These are often going to be failed to fetch, abort errors, or cancelled requests.
        logger.warn(...log);
      } else {
        logger.info(...log);
      }
    });
  }
});
