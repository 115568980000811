// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { FieldFunctionOptions, Reference } from '@apollo/client/cache';
import {
  AddTagToItemMutation,
  AddTagToItemMutationVariables,
  MutationAddTagToItemArgs,
} from '~/common/gql';
import { itemsQueryPaginatedModifier } from '../helpers/itemsQueryPaginated/itemsQueryPaginatedModifier';

type TDocument = AddTagToItemMutation;
type TExisting = Reference;
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationAddTagToItemArgs;
type TVars = AddTagToItemMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction =
  | FieldMergeFunction<TExisting, TIncoming, TOptions>
  | boolean;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 * - Forces a refetch on item list queries requesting items from affected collection
 * - Removes affected item from orphan queries
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [existing, incoming, options] = args;
  const {
    args: {
      input: {
        find: { id: itemId, collectionId },
      },
    },
    cache,
    mergeObjects,
  } = options;
  const itemRef = incoming;

  cache.modify({
    fields: {
      itemsQueryPaginated: itemsQueryPaginatedModifier(itemRef, {}),
    },
  });

  return mergeObjects(existing, incoming);
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
