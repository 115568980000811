// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { WS_GQL_URL } from '~/common/config/runtime';
import { isBrowser } from '~/common/utilities/browser-utils';

// ApolloClient should only route requests via websocket
// when in the browser
// See: https://github.com/apollographql/subscriptions-transport-ws/issues/333
export const wsLink = isBrowser
  ? new GraphQLWsLink(
      createClient({
        url: WS_GQL_URL,
      }),
    )
  : null;
