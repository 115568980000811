// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import isSafeInteger from 'lodash/isSafeInteger';
import toNumber from 'lodash/toNumber';
import { withItemsQueryVariablesFn } from '~/common/cache/helpers/itemsQueryPaginated/types/withItemsQueryVariablesFn';

/**
 *
 * @param it
 * @returns true if query is collection specific
 */
export const getIsAnyCollectionItemsQueryVariables: withItemsQueryVariablesFn =
  (it) => {
    return (
      it &&
      it.variables &&
      it.variables.input &&
      isSafeInteger(toNumber(it.variables?.input?.collectionId)) &&
      toNumber(it.variables?.input?.collectionId) >= 1 // collection ids start at 1
    );
  };
