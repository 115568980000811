// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { TParsedQueryProperties } from '../parseStoreFieldName/TParsedQueryProperties';
import { getIsAnyCollectionItemsQueryVariables } from './filters/getIsAnyCollectionItemsQueryVariables';
import { getIsAnyOrphanItemsQueryVariables } from './filters/getIsAnyOrphanItemsQueryVariables';
import { getIsAnyTodoItemsQueryVariables } from './filters/getIsAnyTodoItemsQueryVariables';
import { getIsAnyTrashItemsQueryVariables } from './filters/getIsAnyTrashItemsQueryVariables';
import { ItemsQueryStoreFieldNameVariables } from './types/ItemsQueryStoreFieldNameVariables';

export const getParsedItemsQueryInfo = (
  parsedQuery: TParsedQueryProperties<ItemsQueryStoreFieldNameVariables>,
) => {
  const isAnyCollectionItemsQuery =
    getIsAnyCollectionItemsQueryVariables(parsedQuery);
  const isAnyOrphanItemsQuery = getIsAnyOrphanItemsQueryVariables(parsedQuery);
  const isAnyTodoItemsQuery = getIsAnyTodoItemsQueryVariables(parsedQuery);
  const isAnyTrashItemsQuery = getIsAnyTrashItemsQueryVariables(parsedQuery);

  return {
    isAnyCollectionItemsQuery,
    isAnyOrphanItemsQuery,
    isAnyTodoItemsQuery,
    isAnyTrashItemsQuery,
  };
};
