// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Modifier, Reference } from '@apollo/client/cache';
import { Item } from '~/common/gql';
import { getIsItemOrphan } from '../itemReference/getIsItemOrphan';
import { getIsItemTodo } from '../itemReference/getIsItemTodo';
import { getIsItemTodoMarkedDone } from '../itemReference/getIsItemTodoMarkedDone';
import { getIsItemTrashed } from '../itemReference/getIsItemTrashed';
import { getItemCollection } from '../itemReference/getItemCollection';
import { parseStoreFieldName } from '../parseStoreFieldName/parseStoreFieldName';
import { getIsItemShouldBeIncludedInItemsQuery } from './getIsItemShouldBeIncludedInItemsQuery';
import { getInsertItemModifier } from './modifier/getInsertItemModifier';
import { getRemoveItemModifier } from './modifier/getRemoveItemModifier';
import { ItemsQueryStoreFieldNameVariables } from './types/ItemsQueryStoreFieldNameVariables';

export const itemsQueryPaginatedModifier = (
  itemRef: Reference,
  override?: {
    isItemOrphan?: boolean;
    isItemTodo?: boolean;
    isItemTodoMarkedDone?: boolean;
    isItemTrashed?: boolean;
    itemCollections?: Item['collection'];
  },
): Modifier<any> => {
  const modifier = (value, details) => {
    const { readField } = details;
    const isItemOrphan =
      override?.isItemOrphan ?? getIsItemOrphan(itemRef, readField);
    const isItemTodo =
      override?.isItemTodo ?? getIsItemTodo(itemRef, readField);
    const isItemTodoMarkedDone =
      override?.isItemTodoMarkedDone ??
      getIsItemTodoMarkedDone(itemRef, readField);
    const isItemTrashed =
      override?.isItemTrashed ?? getIsItemTrashed(itemRef, readField);
    const itemCollections =
      override?.itemCollections ?? getItemCollection(itemRef, readField);
    const insertItemModifier = getInsertItemModifier(itemRef);
    const removeItemModifier = getRemoveItemModifier(itemRef);

    const { storeFieldName } = details;
    const parsedQuery =
      parseStoreFieldName<ItemsQueryStoreFieldNameVariables>(storeFieldName);
    const isItemShouldBeIncludedInItemsQuery =
      getIsItemShouldBeIncludedInItemsQuery({
        item: {
          isOrphan: isItemOrphan,
          isTodo: isItemTodo,
          isTodoMarkedDone: isItemTodoMarkedDone,
          isTrashed: isItemTrashed,
          collections: itemCollections,
        },
        query: {
          storeFieldName,
        },
      });

    if (isItemShouldBeIncludedInItemsQuery) {
      return insertItemModifier(parsedQuery, value, details);
    }

    return removeItemModifier(parsedQuery, value, details);
  };
  return modifier;
};
