// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FieldMergeFunction, FieldPolicy } from '@apollo/client';
import { FieldFunctionOptions } from '@apollo/client/cache';
import {
  ItemDeleteMutation,
  ItemDeleteMutationVariables,
  MutationItemDeleteArgs,
} from '~/common/gql';
import { removeItem } from '../helpers/itemsQueryPaginated/modifier/removeItem';

type TDocument = ItemDeleteMutation;
type TExisting = TDocument['itemDelete'];
type TIncoming = TExisting;
type TReadResult = TIncoming;
type TArg = MutationItemDeleteArgs;
type TVars = ItemDeleteMutationVariables;
type TOptions = FieldFunctionOptions<TArg, TVars>;
type TMergeFunction = FieldMergeFunction<TExisting, TIncoming, TOptions>;
type TPolicyInternal = FieldPolicy<TExisting, TIncoming, TReadResult, TOptions>;
type TPolicy = FieldPolicy<any>; // only any is accepted by in memory cache

/**
 * - Removes item from all items list queries
 * - Removes item from all item search queries
 * - Evicts normalized item storage object from cache
 * @param args
 * @returns
 */
const merge: TMergeFunction = (...args) => {
  const [existing, incoming, options] = args;
  const {
    args: {
      find: { id },
    },
    cache,
    readField,
    toReference,
  } = options;

  const itemRef = toReference(`Item:${id}`);

  const removeItemModifier = (value, details) => {
    return {
      ...value,
      items: removeItem(itemRef, value['items'], details.readField),
    };
  };

  // remove all references
  cache.modify({
    fields: {
      itemsQueryPaginated: removeItemModifier,
      itemSearch: removeItemModifier,
    },
  });

  // evict from normalized data
  cache.evict({
    id: cache.identify(itemRef),
  });

  return incoming;
};

export default {
  merge,
} satisfies TPolicyInternal as TPolicy;
