// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import parser from 'ua-parser-js';
import { UsageEventCreateInput } from '~/common/gql';
import { LS_BROWSER_ID_STORAGE_KEY } from '~/common/utilities/local-store/localStorageKeys';
import { localStore } from '~/common/utilities/local-store/localStore';
import { isPWAInstalled } from '~/common/utilities/pwa-utils';
import { getPersistedId } from '~/common/utilities/session-store/getPersistedId';
import { localSessionStore } from '~/common/utilities/session-store/localSessionStore';
import { SS_WINDOW_ID_STORAGE_KEY } from '~/common/utilities/session-store/sessionStorageKeys';
import { getUTM } from '~/common/utilities/tracking-utils/utm/get-utm';
import { attemptURL } from '~/common/utilities/url-utils/attemptURL';
import { ActionType } from './actionType';
import { AppType } from './appType';

export interface ConvenientUsageEventInputs {
  actionType: ActionType;
  appType?: AppType;
  currentWindow?: Window;
  storage?: Storage;
  itemId?: number;
  srcCollectionId?: number;
  destCollectionId?: number;
  srcUrl?: string;
  srcPath?: string;
  createdAt?: string;
  context?: string;
  label?: string;
  text?: string;
  browserStorage?: Storage;
}

/**
 *
 * Will throw and not work without localstorage
 */
export const getUsageEventInputs = async (
  from: ConvenientUsageEventInputs,
): Promise<UsageEventCreateInput> => {
  const pwa = await isPWAInstalled();

  const {
    actionType,
    appType = pwa ? AppType.PWA : AppType.WEB_APP,
    currentWindow = window,
    storage = localSessionStore,
    createdAt = new Date().toISOString(),
    label,
    browserStorage = localStore,
    ...props
  } = from;

  const ua = parser(currentWindow?.navigator?.userAgent);
  const windowId = getPersistedId(storage, SS_WINDOW_ID_STORAGE_KEY);
  const browserId = getPersistedId(browserStorage, LS_BROWSER_ID_STORAGE_KEY);
  const srcUrl = currentWindow?.location?.href;
  const url = attemptURL(srcUrl);

  const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent } =
    getUTM(url);

  return {
    appType: appType.toString(),
    actionType: actionType.toString(),
    browserType: `${ua.browser.name}/${ua.browser.version}`,
    osType: `${ua.os.name}/${ua.os.version}`,
    isTouchScreen: !!(
      'ontouchstart' in currentWindow ||
      currentWindow?.navigator?.maxTouchPoints
    ),
    windowHeight: currentWindow?.innerHeight,
    windowWidth: currentWindow?.innerWidth,
    srcUrl,
    srcPath: currentWindow?.location?.pathname,
    windowId,
    createdAt,
    label: isString(label)
      ? label
      : isNumber(label)
        ? (label as number).toString()
        : '',
    browserId,
    headerReferrer: currentWindow?.document?.referrer,
    headerAcceptLanguage: currentWindow?.navigator?.language,
    windowUserAgent: currentWindow?.navigator?.userAgent,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    ...props,
  };
};
