// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { INDEX_NOT_FOUND } from './constants';
import overlapsAfterIndex from './overlapsAfterIndex';

/**
 * If the sequences overlap at their tails,
 * then we return the concatination of the two
 * sequences at their overlap starting with
 * the startsWith seq.
 * If sequences do not overlap, then we return
 * undefined.
 *
 * example:
 * startsWith = [1, 2, 3]
 * endsWith = [2, 3, 4]
 * returns = [1, 2, 3, 4]
 * @param startsWith seq to start with
 * @param endsWith seq to end with
 * @param equals a function that returns true if two seqequence
 * items are equal
 * @param preferStart if true the startsWith seq will override
 * values in endsWith seq at overlap
 * @returns sequences that are joined by their overlap, if possible
 */
const mergeOverlappingSequences = ({
  preferStart,
  startsWith: start,
  endsWith: end,
  equals,
}: {
  preferStart: boolean;
  startsWith: readonly any[];
  endsWith: readonly any[];
  equals: (a: any, b: any) => boolean;
}) => {
  const overlapIndex = overlapsAfterIndex({
    seq: start,
    overlapsWith: end,
    equals,
  });
  if (overlapIndex === INDEX_NOT_FOUND) return undefined;

  if (!preferStart) {
    // must account for cases where starting sequence is larger than the ending sequence
    return [
      ...start.slice(0, overlapIndex),
      ...end,
      ...start.slice(overlapIndex + end.length),
    ];
  }

  return [...start, ...end.slice(start.length - overlapIndex)];
};

export default mergeOverlappingSequences;
