import * as Types from '../gql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsageEventCreateMutationVariables = Types.Exact<{
  input: Types.UsageEventCreateInput;
}>;


export type UsageEventCreateMutation = { __typename?: 'Mutation', usageEventCreate: boolean };


export const UsageEventCreateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"usageEventCreate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UsageEventCreateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"usageEventCreate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode;
export type UsageEventCreateMutationFn = Apollo.MutationFunction<UsageEventCreateMutation, UsageEventCreateMutationVariables>;

/**
 * __useUsageEventCreateMutation__
 *
 * To run a mutation, you first call `useUsageEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsageEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usageEventCreateMutation, { data, loading, error }] = useUsageEventCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsageEventCreateMutation(baseOptions?: Apollo.MutationHookOptions<UsageEventCreateMutation, UsageEventCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsageEventCreateMutation, UsageEventCreateMutationVariables>(UsageEventCreateDocument, options);
      }
export type UsageEventCreateMutationHookResult = ReturnType<typeof useUsageEventCreateMutation>;
export type UsageEventCreateMutationResult = Apollo.MutationResult<UsageEventCreateMutation>;
export type UsageEventCreateMutationOptions = Apollo.BaseMutationOptions<UsageEventCreateMutation, UsageEventCreateMutationVariables>;