// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import isArray from 'lodash/isArray';
import isObjectLike from 'lodash/isObjectLike';

export const recursivelyRedactObject = <T>(
  obj: T,
  redactKeys: string[],
  redactionText: string = '[redacted]',
) => {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    if (redactKeys.includes(key)) {
      result[key] = redactionText;
    } else if (isObjectLike(value) && !isArray(value)) {
      result[key] = recursivelyRedactObject(value, redactKeys, redactionText);
    } else {
      result[key] = value;
    }
  }
  return result as T;
};
