// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { INDEX_NOT_FOUND } from './constants';

/**
 * Finds the index in sequence seq where overlapsWith starts.
 *
 * example:
 *  seq = [1, 2, 3]
 *  overlapsWith = [2, 3, 4]
 *  result = 1
 *  b/c [2, 3] is the start of the overlapsWith sequence.
 * @param seq starting sequence
 * @param overlapsWith seq ends with the start of the overlapsWith
 * seq
 * @param equals a function that returns true if two seqequence
 * items are equal
 * @returns the index of the first element in the overlap
 */
const overlapsAfterIndex = ({
  seq: head,
  overlapsWith: tail,
  equals,
}: {
  seq: readonly any[];
  overlapsWith: readonly any[];
  equals: (a: any, b: any) => boolean;
}) => {
  const [first, ...rest] = tail;

  for (let i = 0; i < head.length; i++) {
    const val = head[i];

    if (equals(first, val)) {
      // check the rest overlap
      const max = Math.min(head.length - i - 1, rest.length);

      for (let j = 0; j < max; j++) {
        const v1 = head[i + j + 1];
        const v2 = rest[j];

        if (!equals(v1, v2)) {
          return INDEX_NOT_FOUND;
        }
      }

      return i;
    }
  }

  return INDEX_NOT_FOUND;
};

export default overlapsAfterIndex;
