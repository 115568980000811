// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import noop from 'lodash/noop';
import omit from 'lodash/omit';
import { SyntheticEvent, useCallback } from 'react';
import { useUsageEventCreateMutation } from '~/common/gql/usage-event.generated';
import { AppType, getUsageEventInputs } from '~/common/utilities/usage-event';
import { getUsageAttributes } from '~/common/utilities/usage-event/getUsageAttributes';
import { getUsageEventMessage } from '~/common/utilities/usage-event/getUsageEventMessage';
import { LogUsageEventInput } from '~/common/utilities/usage-event/LogUsageEventInput';
import { datadog } from '~/lib/datadog/datadog-logger';

export const useUsageEventCreateMutationHelper = () => {
  const [createUsageEvent] = useUsageEventCreateMutation();

  const logUsageEvent = useCallback(
    async (logInput: LogUsageEventInput) => {
      const { appType = AppType.WEB_APP, metadata, ...event } = logInput;
      try {
        const input = await getUsageEventInputs({ appType, ...event });
        const variables = {
          input,
        };
        createUsageEvent({
          variables,
          // Throws error despite error already being handled and logged
          // by logErrorsToDatadogLink
          onError: noop,
        });

        const log = datadog.generateLog({
          type: '[Usage Event]',
          message: getUsageEventMessage(input, metadata),
          context: {
            event: {
              ...input,
            },
          },
        });
        // log to datadog
        datadog.logger.then((logger) => logger.info(...log));
      } catch (e) {}
    },

    [createUsageEvent],
  );

  const logUsageEventWithAttributes = (
    event: SyntheticEvent,
    logInput: LogUsageEventInput,
    exclude?: Array<keyof LogUsageEventInput>,
  ) => {
    const eventAttribtutes = getUsageAttributes(event);
    const selectedAttributes = omit(eventAttribtutes, exclude);
    logUsageEvent({
      ...logInput,
      ...selectedAttributes,
    });
  };

  return { logUsageEvent, logUsageEventWithAttributes };
};
