// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { useItemDeleteMutation } from '~/common/gql/item.generated';
import { ActionType } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

export const useItemDeleteMutationHelper = createMutationHelperHook(
  useItemDeleteMutation,
  {
    errorMessage: 'There was an error deleting this item',
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.DELETE_BOOKMARK,
        itemId: variables.input.id,
      }),
      afterMutation: (variables) => ({
        actionType: ActionType.DELETE_BOOKMARK,
        itemId: variables.input.id,
      }),
    },
  },
);
